import request from '@/common/http/request'
// 下注记录
export function getBetRecordsList(data) {
    return request({
        url: '/gameOrder/pageBetting',
        method: 'post',
        data
    })
}
// 充值记录
export function getDepositRecords(data) {
    return request({
        url: '/memberRechargeOrder/getPageList',
        method: 'post',
        data
    })
}
// 提现记录
export function getWithdrawRecords(data) {
    return request({
        url: '/memberWithdrawOrder/getPageList',
        method: 'post',
        data
    })
}
