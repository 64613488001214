import request from '@/common/http/request'

// 获取分享信息
export function getApkUrl(data) {
    return request({
        url: '/home/sysBaseConfigAndApkUrl',
        method: 'post',
        data
    })
}
// 获取pwa存储的值
export function getPWAValue(data) {
    return request({
        url: '/paw/get',
        method: 'post',
        data,
        isNoToastErr: true
    })
}
// 修改pwa存储的值
export function setPWAValue(data) {
    return request({
        url: '/paw/set',
        method: 'post',
        data,
        isNoToastErr: true
    })
}
