<template>
    <van-popup v-model="show" mode="center">
        <div class="content">
            <div class="title">
                {{ $t("lang.tips") }}
            </div>
            <div class="tip">
                {{ $t("lang.isCloseGame") }}
            </div>
            <div class="btns">
                <div class="btn" @click="handleOut">
                    {{ $t("lang.confirm") }}
                </div>
                <div class="btn" @click="close">
                    {{ $t("lang.cancel") }}
                </div>
            </div>
        </div>
    </van-popup>
</template>

<script>
export default {
    data() {
        return {
            show: false
        }
    },
    methods: {
        open() {
            this.show = true
        },
        close() {
            this.show = false
        },
        // 退出游戏
        handleOut() {
            this.show = false
            this.$nextTick(() => {
                this.$emit('gameOut')
            })
        }
    }
}
</script>

<style scoped lang="scss">
.van-popup {
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
}

.content {
    padding: .2rem .3rem .3rem;
    box-sizing: border-box;
    width: 6rem;
    background: linear-gradient(180deg, #223053, #232d46);
    border-radius: .14rem;
    position: relative;
    color: #ffffff;
    font-size: .24rem;
    text-align: center;
    padding-top: .6rem;

    .title {
        font-size: .3rem;
        color: #fff;
        line-height: .7rem;
        background-image: url(@/assets/common/top.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: top left;
        height: .7rem;
        width: 4.2rem;
        position: absolute;
        left: calc(50% - 2.1rem);
        top: -.175rem;
    }

    .tip {
        margin: .3rem 0 .45rem;
        height: .36rem;
        line-height: .36rem;
    }

    .btns {
        display: flex;
        justify-content: space-between;
        column-gap: .3rem;
        height: .7rem;
        line-height: .72rem;

        .btn {
            flex: 1;
            padding: 0 .1rem;
            border-radius: .14rem;
            cursor: pointer;
            color: #fff;

            &:first-child {
                background: #286aff;
            }

            &:last-child {
                background: #2a2f39;
            }
        }
    }
}
</style>
