import request from '@/common/http/request'

// 获取分享信息
export function getMemberExtend(data) {
    return request({
        url: '/memberExtend/info',
        method: 'post',
        data
    })
}
