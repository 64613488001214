<template>
    <div class="popup" v-if="isLogin">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <div class="header">
                    <div>{{ $t("Setting.Setting") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="item">
                        <div>{{ $t("Setting.AccountID") }}</div>
                        <div> {{ userInfo.id }}</div>
                    </div>
                    <div class="item">
                        <div>{{ $t("Setting.PhoneNumber") }}</div>
                        <div> {{ userPhone }}</div>
                    </div>
                    <div class="item" @click="$refs.changePwdPopup.open()">
                        <div>{{ $t("Setting.Password") }}</div>
                        <div>{{ $t("Setting.Change") }}</div>
                    </div>
                </div>
            </div>
        </van-popup>
        <changePwdPopup ref="changePwdPopup"></changePwdPopup>
    </div>
</template>

<script>
import {
    getUserInfo
} from '@/api/user'
import changePwdPopup from '@/components/changePwdPopup'
export default {
    name: 'settingPopup',
    components: { changePwdPopup },
    data() {
        return {}
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        userInfo: {
            get() {
                return this.$store.getters.userInfo
            },
            set() { }
        },
        userPhone() {
            const phone = this.userInfo && this.userInfo.phone
            return phone ? this.maskNumber(phone) : ''
        },
        show: {
            get() {
                return this.$store.getters.isShowSettingPopup
            },
            set(val) {
                return val
            }
        }
    },
    created() { },
    methods: {
        close() {
            this.$store.dispatch('user/setIsShowSettingPopup', false)
        },
        open() {
            this.init()
        },
        init() {
            if (!this.userInfo.id) {
                this.getUserInfo()
            }
        },
        getUserInfo() {
            return new Promise((resolve, reject) => {
                getUserInfo().then((res) => {
                    const {
                        result,
                        code
                    } = res
                    if (code === 0) {
                        this.userInfo = result
                        resolve(result)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        maskNumber(phoneNumber) {
            const str = phoneNumber.toString()
            if (str.length <= 4) {
                return str
            }
            const firstTwo = str.slice(0, 2)
            const lastTwo = str.slice(-2)
            return `${firstTwo}***${lastTwo}`
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';
.popup {

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: #516382;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                padding: 30px 40px;

                .item {
                    height: 46px;
                    padding: 0 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: .24rem;
                    background: $bg-list-color;
                    margin-bottom: 10px;

                    >div {
                        color: #ffffff;
                        font-size: .24rem;

                        &:last-child {
                            color: $font-theme-color;
                        }
                    }

                    &:last-child {
                        >div {
                            &:last-child {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
