<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <div class="header">
                    <div>{{ $t('turntable.title') }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="money_info">
                        <div class="nowMoney">
                            {{ CurrencyType }}
                            <span>{{ lotteriesAmt || 0 }}</span>
                        </div>
                        <div class="targetMoney">
                            {{ $t('turntable.targetAmount') }} <span>{{ targetAmt }}</span>
                        </div>
                        <div class="cashBtn" @click="onLotteryWithdraw">
                            {{ $t('turntable.cashOut') }}
                        </div>
                    </div>
                    <div class="turntable_content">
                        <div class="turntable">
                            <div class="turntable_bg">
                                <img src="@/assets/turntable/turntable.png" class="turntablebg" alt="">
                                <img src="@/assets/turntable/decoration1.png" alt="" class="decoration1 decoration">
                                <img src="@/assets/turntable/decoration2.png" alt="" class="decoration2 decoration">
                                <img src="@/assets/turntable/decoration3.png" alt="" class="decoration3 decoration">
                                <img src="@/assets/turntable/decoration4.png" alt="" class="decoration4 decoration">
                                <img src="@/assets/turntable/decoration5.png" alt="" class="decoration5 decoration">
                                <img src="@/assets/turntable/decoration6.png" alt="" class="decoration6 decoration">
                                <img src="@/assets/turntable/decoration7.png" alt="" class="decoration7 decoration">
                                <!-- <img src="@/assets/turntable/decoration8.png" alt="" class="decoration8 decoration"> -->
                            </div>
                            <img src="@/assets/turntable/decoration8.png" alt="" class="decoration8 decoration">
                            <!-- <img src="@/assets/turntable/turntable-light.png" alt="" class="turntable_light"> -->
                            <div class="turntable_main" :style="getMainStyle">
                                <div class="item" v-for="(citem, cindex) in prizeList" :key="cindex"
                                    :style="[getRotateAngle(cindex)]">
                                    <div v-if="citem.pinduoduoDrawType === 'IMMOBILIZATION' && isFirstPrizeDraw != 'Yes'"
                                        class="item-content" :style="[getCorrectAngle(cindex)]">
                                        <div class="item-name">
                                            {{ citem.amount }}
                                        </div>
                                        <img :src="citem.img || require('@/assets/turntable/icon-money.png')"
                                            class="item-img">
                                    </div>
                                    <div v-else class="item-random" :style="[getCorrectAngle(cindex)]">
                                        <div class="item-name">
                                            ???
                                        </div>
                                        <img :src="citem.img || require('@/assets/turntable/icon-money.png')"
                                            class="item-img">
                                    </div>
                                </div>
                                <div class="turntable_main__shadow"></div>
                            </div>
                            <div class="turntable_btn" @click="onTurn">
                                <div class="count">
                                    X {{ drawCount || 0 }}
                                </div>
                                <img src="@/assets/turntable/turntable-btn.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="share">
                        <div class="share_info" @click="copyText(inviteUrl)">
                            <div v-if="!isHideShareLink">{{ inviteUrl }}</div>
                            <img src="@/assets/turntable/icon_copy.png" alt="">
                        </div>
                        <div class="share_btn" @click="onNavTo({ url: '/affiliate' })">
                            {{ $t('turntable.InviteFriends') }}
                        </div>
                        <div class="countDown">
                            <span>{{ $t('turntable.downTimeTips1') }}</span>
                            <a-statistic-countdown :value="timestamp" @finish="countDownFinish" />
                            <span>{{ $t('turntable.downTimeTips2') }}</span>
                            <span>{{ $t('turntable.downTimeTips3') }}</span>
                        </div>
                    </div>
                    <div class="tabs">
                        <div class="tabs-header">{{ $t('turntable.tab2') }}</div>
                        <div class="tabs-content">
                            <div class="tabs-content-inset">
                                <div class="tabs-content-item records">
                                    <div v-if="recordList.length">
                                        <div class="tr" v-for="(item, index) in recordList" :key="index">
                                            <div class="td">
                                                {{ item.createTime }}
                                            </div>
                                            <div class="td money">{{ CurrencyType }}+{{ item.amount }}</div>
                                        </div>
                                        <div v-if="recordLoading" class="last-tips">
                                            <a-spin>
                                                <a-icon slot="indicator" type="loading" spin />
                                            </a-spin>
                                            {{ $t("lang.Loading") }}
                                        </div>
                                        <div class="last-tips" v-else-if="recordHasMore"
                                            @click="getTurntableDrawRecords">
                                            {{ $t("lang.LoadMore") }}
                                        </div>
                                        <div class="last-tips">{{ $t("lang.AllLoaded") }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rule_box">
                        <div class="title">
                            {{ $t("signin.Rules") }}
                        </div>
                        <div class="rule" v-html="rules"></div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { Dialog, Toast } from 'vant'
import handleCopy from '@/utils/copy.js'
import {
    domain,
    inviteCodeKey
} from '@/common/http/index.js'
import {
    getEventsHomeShow
} from '@/api/home'
import { getHideShareStatus, getShareinfo } from '@/api/share'
import { getPinduoduoInfo, getPinduoduoRecordList, pinduoduoPrizeDraw, pinduoduoWithdraw } from '@/api/pinduoduo'
export default {
    name: 'turntablePop',
    components: {},
    data() {
        return {
            firstLoaded: true,
            amt: 0, // 中奖金额
            drawCount: 0,
            inviteUrl: '', // 邀请链接
            isHideShareLink: false, // 是否可以分享
            isFirstPrizeDraw: 'No', // 是否第一次抽奖
            lotteriesAmt: 0, // 累计中奖金额
            targetAmt: 0, // 可提现目标金额
            timeData: {},
            timestamp: '',
            prizeList: [], // 奖品列表
            isTurnIng: false, // 是否转动
            startRotateDegree: 0, // 开始转动的角度
            rotateAngle: 0, // 设置指针默认指向的位置,现在是默认指向2个扇形之间的边线上
            rotateTransition: '',
            tabAct: 1,
            // 中奖名单
            winningList: [],
            getWinningListInterval: null,
            dataScrollStyle: '',
            dataScrollInterval: null,
            recordPageNo: 0,
            recordHasMore: false,
            recordLoading: false,
            recordList: [],
            rules: '' // 活动说明
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        eventId: {
            get() {
                return this.$store.state.user.pinduoduoEventId
            },
            set() { }
        },
        show: {
            get() {
                return this.$store.getters.isShowPinduodouPopup
            },
            set(val) {
                return val
            }
        },
        getRotateAngle() {
            return index => {
                return {
                    transform: `rotate(${(360 / this.prizeList.length) * index}deg) skewX(0deg) skewY(${360 / this.prizeList.length - 90}deg)`
                }
            }
        },
        getCorrectAngle() {
            return index => {
                return {
                    transform: `skewY(${90 - 360 / this.prizeList.length}deg) skewX(0deg) rotate(${180 / this.prizeList.length}deg)`
                }
            }
        },
        getMainStyle() {
            return `
				transform: ${this.rotateAngle};
				transition: ${this.rotateTransition};
			`
        }
    },
    created() {
    },
    methods: {
        open() {
            if (this.firstLoaded) {
                this.init()
            }
        },
        close() {
            this.$store.dispatch('user/setIsShowPinduoduoPopup', { id: '', show: false })
        },
        init() {
            this.recordPageNo = 0
            this.getShareInfo()
            this.getHideShareStatus()
            this.getInfo()
            this.getTurntableDrawRecords()
        },
        getHideShareStatus() {
            getHideShareStatus({}, {
                custom: {
                    toast: false
                }
            }).then((res) => {
                if (res.code) return
                this.isHideShareLink = res.result
            })
        },
        getShareInfo() {
            getShareinfo().then((res) => {
                if (res.code) return
                const D = res.result
                this.shareData = D
                const userAgent = navigator.userAgent || navigator.vendor || window.opera
                if (/Windows/.test(userAgent)) {
                    // console.log('windows')
                    this.inviteUrl = window.location.origin + '?' + inviteCodeKey + '=' + D.inviteCode
                } else {
                    // console.log('app')
                    this.inviteUrl = domain + '?' + inviteCodeKey + '=' + D.inviteCode
                }
            })
        },
        getInfo() {
            const that = this
            if (this.eventId) {
                that.getTurntableInfo(this.eventId)
            } else {
                getEventsHomeShow().then((res) => {
                    if (res.code) return
                    const D = res.result.eventManageList
                    const item = D.filter(item => item.eventType === 'pinduoduo')
                    const that = this
                    that.eventId = item[0].id
                    that.getTurntableInfo(that.eventId)
                })
            }
        },
        getDownTimes(time) {
            const that = this
            let zoneTime = -3
            if (that.BelongNation === 'Pakistan') {
                zoneTime = 5
            }
            const currentTime = that.getLocalTime(zoneTime)
            const launchTime = time ? new Date(time).getTime() : that.getLocalTime(zoneTime)
            if (launchTime > currentTime) {
                that.timestamp = launchTime - currentTime
                that.timestamp = Date.now() + that.timestamp
            }
        },
        countDownFinish() {
            if (this.timestamp !== '') this.getTurntableInfo()
        },
        getLocalTime(i) {
            if (typeof i !== 'number') return
            const d = new Date()
            const len = d.getTime()
            const offset = d.getTimezoneOffset() * 60000
            const utcTime = len + offset
            return utcTime + 3600000 * i
        },
        getTurntableInfo(id) {
            return new Promise((resolve, reject) => {
                getPinduoduoInfo({ id }).then((res) => {
                    this.LoadingNum++
                    if (res.code) return
                    const D = res.result
                    this.rules = D.value0.eventContent
                    this.isFirstPrizeDraw = D.value1.firstPrizeDraw
                    this.prizeList = D.value0.pinduoduoModelDetailVoList || []
                    if (D.value1.firstPrizeDraw === 'No') this.getDownTimes(D.value1.offTime) // 过期时间
                    this.drawCount = Number(D.value1.lotteriesCount || 0) + Number(D.value1
                        .lotteriesHelpCount || 0)
                    this.lotteriesAmt = Number(D.value1.lotteriesAmt || 0) // 累计中奖金额
                    this.targetAmt = Number(D.value0.targetAmt || 0) // 可提现目标金额
                    this.firstLoaded = false
                    resolve(this.prizeList)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 获取抽奖记录
        getTurntableDrawRecords() {
            this.recordPageNo++
            if (this.recordLoading) return
            this.recordLoading = true
            getPinduoduoRecordList({
                eventId: this.eventId,
                pageNo: this.recordPageNo,
                pageSize: 20
            }).then((res) => {
                if (res.code) return
                const D = res.result
                this.recordList = this.recordList.concat(D.records)
                this.recordHasMore = D.total > this.recordList.length
            }).finally(() => {
                setTimeout(() => {
                    this.recordLoading = false
                }, 200)
            })
        },
        onTurn() {
            if (this.isTurnIng) return
            this.isTurnIng = true
            const params = {
                eventId: this.eventId
            }
            pinduoduoPrizeDraw(params).then((res) => {
                const that = this
                if (res.code === 0) {
                    that.isTurnIng = true
                    const D = res.result
                    let foundIdx = -1
                    const randomList = []
                    let idx = 0
                    that.prizeList.forEach((x, i) => {
                        if (x.amount === D && x.pinduoduoDrawType === 'IMMOBILIZATION') {
                            foundIdx = i
                        }
                        if (x.pinduoduoDrawType === 'RANDOM') {
                            randomList.push(i)
                        }
                    })
                    if (foundIdx !== -1) {
                        idx = foundIdx
                    } else {
                        if (randomList.length > 0) {
                            const randomIndex = Math.floor(Math.random() * randomList.length)
                            idx = randomList[randomIndex]
                        }
                    }
                    const list = that.turntableList[that.typeAct].luckyWheelModelDetailOddsVoList
                    list.forEach((x, i) => {
                        if (x.amount === D) {
                            idx = i
                        }
                    })
                    that.run(idx).then(() => {
                        Dialog.alert({
                            title: D !== 0 ? that.$t('turntable.drawTitle1') : that.$t('turntable.drawTitle2'),
                            message: D !== 0 ? `${that.$t('turntable.drawContent1')}${that.CurrencyType} ${D}` : that.$t('turntable.drawContent2'),
                            confirmButtonText: that.$t('lang.confirm'),
                            confirmButtonColor: '#000000',
                            closeOnClickOverlay: true
                        }).then(() => {
                            if (that.isFirstPrizeDraw === 'Yes') {
                                // that.getDownTimes(D.offTime)
                                that.isFirstPrizeDraw = 'No'
                                that.startRotateDegree = 0 // 开始转动的角度
                                that.rotateAngle = 'rotate(0deg)'
                                that.rotateTransition = 'transform 0s cubic-bezier(0.250, 0.460, 0.455, 0.995)'
                            }
                            that.lotteriesAmt = Number(D) + that.lotteriesAmt // 累计中奖金额
                            that.drawCount = that.drawCount - 1 > 0 ? that.drawCount - 1 : 0
                            that.getTurntableInfo()
                            that.recordPageNo = 0
                            that.recordHasMore = true
                            that.recordLoading = false
                            that.recordList = []
                            that.getTurntableDrawRecords()
                        })
                    })
                } else if (res.code === -1) {
                    this.isTurnIng = false
                }
            })
        },
        onLotteryWithdraw() {
            const that = this
            const params = {
                id: this.eventId
            }
            pinduoduoWithdraw(params).then((res) => {
                if (res.code === 0) {
                    Dialog.alert({
                        title: that.$t('turntable.drawTitle1'),
                        message: `${that.$t('turntable.withdrawSuccessfully')} ${that.targetAmt} !`,
                        confirmButtonText: that.$t('lang.confirm'),
                        confirmButtonColor: '#000000',
                        closeOnClickOverlay: true
                    }).then(() => {
                        that.startRotateDegree = 0
                        that.rotateAngle = 'rotate(0deg)'
                        that.rotateTransition = 'transform 0s cubic-bezier(0.250, 0.460, 0.455, 0.995)'
                        that.getTurntableInfo()
                    })
                }
            })
        },
        run(index) {
            return new Promise((resolve, reject) => {
                const duration = 5
                this.rotateTransition = `transform ${duration}s cubic-bezier(0.250, 0.460, 0.455, 0.995)`
                const length = this.prizeList.length
                const rotateAngle = this.startRotateDegree + 10 * 360 + 360 - (180 / length + (360 / length) *
                    index) - (this.startRotateDegree % 360)
                this.startRotateDegree = rotateAngle
                this.rotateAngle = `rotate(${rotateAngle}deg)`
                setTimeout(() => {
                    resolve()
                    this.isTurnIng = false
                }, duration * 1000 + 1000)
            })
        },
        copyText(content) {
            handleCopy(
                {
                    content,
                    success: (msg) => {
                        Toast(msg)
                    },
                    error: (msg) => {
                        Toast(msg)
                    }
                },
                this
            )
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {

        .van-popup {
            &.van-popup--center {
                width: 82.6% !important;
                height: max-content !important;
                max-width: 9rem !important;
                min-height: 8.6rem !important;
                max-height: 64.5% !important;
            }

            .content {
                width: 100% !important;
                padding-bottom: .4rem;

                .header {
                    height: .94rem;
                    padding: 0 .28rem;

                    &>div:first-child {
                        font-size: .3rem;
                    }

                    .close {
                        font-size: .3rem;
                    }
                }

                .box {
                    padding: .4rem .4rem 0;

                    .money_info {
                        margin-bottom: .12rem;
                        padding: .22rem;
                        background: #17191f;
                        font-size: .2rem;

                        .nowMoney {

                            span {
                                margin-left: .06rem;
                            }
                        }

                        .targetMoney {
                            margin-bottom: .1rem;

                            span {
                                margin-left: .06rem;
                            }
                        }

                        .cashBtn {
                            height: .56rem;
                            line-height: .56rem;
                            padding: 0 .8rem;
                            font-size: .2rem;
                        }
                    }

                    .turntable_content {
                        .turntable {
                            height: 6.4rem;

                            .decoration8 {
                                top: 0.8rem;
                                width: .22rem;
                                height: 1.3rem;
                            }

                            .turntable_bg {
                                width: 4.65rem;
                                height: 4.65rem;

                                .decoration {
                                    position: absolute;

                                    &.decoration1 {
                                        top: -0.08rem;
                                        left: -0.5rem;
                                        width: .8rem;
                                        height: .8rem;
                                    }

                                    &.decoration2 {
                                        top: -.48rem;
                                        right: .6rem;
                                        width: .7rem;
                                        height: .56rem;
                                    }

                                    &.decoration3 {
                                        top: -.08rem;
                                        right: -0.82rem;
                                        width: 52px;
                                        height: 62px;
                                    }

                                    &.decoration4 {
                                        bottom: -0.3rem;
                                        left: -0.5rem;
                                        width: .92rem;
                                        height: 1.2rem;
                                    }

                                    &.decoration5 {
                                        bottom: -.6rem;
                                        left: .8rem;
                                        width: .66rem;
                                        height: .62rem;
                                    }

                                    &.decoration6 {
                                        bottom: -.8rem;
                                        right: -0.64rem;
                                        width: 1.26rem;
                                        height: 1.32rem;
                                    }

                                    &.decoration7 {
                                        top: -.26rem;
                                        z-index: -1;
                                        left: 50%;
                                        width: .84rem;
                                        height: .38rem;
                                    }
                                }
                            }

                            .turntable_main {
                                width: 200px;
                                height: 200px;
                                left: calc(50% - 100px);
                                bottom: calc(50% - 100px);

                                .item {

                                    .item-content {

                                        .item-name {
                                            padding-top: .2rem;
                                            padding-bottom: .2rem;
                                            font-size: .24rem;
                                        }

                                        .item-img {
                                            width: .4rem;
                                            height: .4rem;
                                        }
                                    }

                                    .item-random {

                                        .item-name {
                                            padding-top: .2rem;
                                            padding-bottom: .2rem;
                                            font-size: .24rem;
                                        }

                                        .item-img {
                                            width: .4rem;
                                            height: .4rem;
                                        }
                                    }
                                }
                            }

                            .turntable_btn {
                                position: absolute;
                                width: 65px;
                                height: 65px;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                z-index: 11;
                                cursor: pointer;

                                >img {
                                    width: 100%;
                                    height: 100%;
                                }

                                .count {
                                    position: absolute;
                                    z-index: 12;
                                    font-weight: bold;
                                    top: 50%;
                                    left: 50%;
                                    color: #9e652f;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }
                    }

                    .share {
                        margin-bottom: .32rem;
                        padding: .24rem;

                        .share_info {
                            font-size: .2rem;

                            img {
                                width: 12px;
                                height: 13px;
                                margin-left: 7px;
                                cursor: pointer;
                            }
                        }

                        .share_btn {
                            width: max-content;
                            padding: 0 .24rem;
                            height: .54rem;
                            line-height: .54rem;
                            font-size: .2rem;
                            margin: .2rem auto 0;
                        }

                        .countDown {
                            font-size: .22rem;
                            margin: .14rem 0 0;
                            row-gap: .1rem;

                            ::v-deep .ant-statistic {
                                height: .48rem;
                                line-height: .2rem;
                            }

                            ::v-deep .ant-statistic-content-value {
                                font-size: .26rem;
                                margin: 0 .1rem;
                            }
                        }
                    }

                    .tabs {
                        margin: 0 0 .2rem;
                        border-radius: .24rem;

                        .tabs-header {
                            font-size: .26rem;
                            padding: 0 .28rem;
                            height: .5rem;
                            line-height: .48rem;

                            .tabs-header-item {
                                padding: 0 .2rem;
                            }
                        }

                        .tabs-content {
                            border-radius: .24rem;
                            padding: .2rem .28rem;

                            .tabs-content-item {
                                height: 6.22rem;

                                &.records {

                                    .last-tips {
                                        line-height: .62rem;
                                        font-size: .28rem;
                                    }

                                    .empty {
                                        font-size: .28rem;

                                        .empty_img {
                                            width: 3.4rem;
                                            object-fit: contain;
                                        }
                                    }
                                }

                                .tr {
                                    height: .62rem;
                                    font-size: .22rem;
                                }
                            }
                        }
                    }

                    .rule_box {
                        border: .02rem solid #2a2f39;
                        font-size: .2rem;
                        padding: .2rem;

                        .title {
                            font-size: .24rem;
                            margin-bottom: .2rem;
                        }
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: #516382;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 20px 30px 0;
                overflow-y: scroll;

                .money_info {
                    margin-bottom: 16px;
                    padding: 12px 12px 16px;
                    background: #17191f;
                    border-radius: .24rem;
                    text-align: center;
                    font-size: 15px;

                    .nowMoney {
                        color: #f3cc70;

                        span {
                            color: #f3cc70;
                            margin-left: 6px;
                        }
                    }

                    .targetMoney {
                        color: #f3cc70;
                        margin-bottom: 8px;

                        span {
                            margin-left: 6px;
                        }
                    }

                    .cashBtn {
                        margin: 0 auto;
                        width: max-content;
                        max-width: 50%;
                        height: 41px;
                        line-height: 41px;
                        padding: 0 .4rem;
                        color: $font-btn-color;
                        background: $bg-join-btn;
                        border-radius: .24rem;
                        white-space: nowrap;
                        cursor: pointer;
                        font-size: 18px;
                        font-weight: bold;
                    }
                }

                .turntable_content {
                    .turntable {
                        position: relative;
                        width: 100%;
                        height: 479px;
                        margin: 0 auto;
                        z-index: 2;

                        .decoration8 {
                            position: absolute;
                            z-index: 13;
                            top: 60px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 16px;
                            height: 97px;
                        }

                        .turntable_bg {
                            width: 348px;
                            height: 348px;
                            vertical-align: middle;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 11;
                            border: 4px solid #fdcb20;
                            border-radius: 50%;

                            .turntablebg {
                                width: 100%;
                                height: 100%;
                            }

                            .decoration {
                                position: absolute;

                                &.decoration1 {
                                    top: -4px;
                                    left: -40px;
                                    width: 59px;
                                    height: 59px;
                                }

                                &.decoration2 {
                                    top: -35px;
                                    right: 44px;
                                    width: 52px;
                                    height: 43px;
                                }

                                &.decoration3 {
                                    top: -7px;
                                    right: -61px;
                                    width: 78px;
                                    height: 93px;
                                }

                                &.decoration4 {
                                    bottom: -24px;
                                    left: -46px;
                                    width: 69px;
                                    height: 90px;
                                }

                                &.decoration5 {
                                    bottom: -40px;
                                    left: 40px;
                                    width: 49px;
                                    height: 46px;
                                }

                                &.decoration6 {
                                    bottom: -56px;
                                    right: -39px;
                                    width: 94px;
                                    height: 99px;
                                }

                                &.decoration7 {
                                    top: -18px;
                                    z-index: -1;
                                    left: 50%;
                                    width: 62px;
                                    height: 28px;
                                    transform: translateX(-50%);
                                    opacity: 0.6;
                                }

                                &.decoration8 {
                                    top: -13px;
                                    left: 50%;
                                    width: 16px;
                                    height: 97px;
                                    transform: translateX(-50%);
                                }
                            }
                        }

                        .turntable_light {
                            width: 300px;
                            height: 300px;
                            vertical-align: middle;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            // z-index: 12;
                        }

                        .turntable_main {
                            position: absolute;
                            background: transparent;
                            border: 2px solid #999999;
                            width: 300px;
                            height: 300px;
                            left: calc(50% - 150px);
                            bottom: calc(50% - 150px);
                            overflow: hidden;
                            border-radius: 50%;
                            padding: 1px;
                            box-sizing: border-box;
                            transition: transform 8s cubic-bezier(0.250, 0.460, 0.455, 0.995);
                            z-index: 11;

                            &__shadow {
                                position: absolute;
                                overflow: hidden;
                                border-radius: 50%;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                box-shadow: 0 0 38px rgba(0, 0, 0, 1) inset;
                                z-index: 10;
                            }

                            .item {
                                overflow: hidden;
                                position: absolute;
                                top: -50%;
                                left: 50%;
                                width: 100%;
                                height: 100%;
                                transform-origin: 0 100%;
                                border-right: 1px solid #d8d8d8;
                                background: #FFD939;

                                &:nth-child(2n) {
                                    background: #C11E0A;

                                    .item_name {
                                        text-stroke: 1px #F5A100;
                                        background: linear-gradient(0deg, #FFD939 0%, #F8FCFF 100%);
                                        -webkit-text-stroke: 1px #F5A100;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                    }
                                }

                                .item-content {
                                    position: absolute;
                                    padding-top: 0;
                                    box-sizing: border-box;
                                    width: 100%;
                                    height: 100%;
                                    left: -50%;
                                    bottom: -50%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .item-name {
                                        padding-top: 18px;
                                        padding-bottom: 10px;
                                        font-size: 18px;
                                        text-stroke: 1px #F5A100;
                                        background: linear-gradient(0deg, #C11E0A 0%, #FFD939 100%);
                                        -webkit-text-stroke: 1px #F5A100;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                    }

                                    .item-img {
                                        width: 29px;
                                        height: 29px;
                                    }
                                }

                                .item-random {
                                    position: absolute;
                                    box-sizing: border-box;
                                    width: 100%;
                                    height: 100%;
                                    left: -50%;
                                    bottom: -50%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .item-name {
                                        padding-top: 18px;
                                        padding-bottom: 10px;
                                        font-size: 18px;
                                        text-stroke: 1px #F5A100;
                                        background: linear-gradient(0deg, #FFD939 0%, #F8FCFF 100%);
                                        -webkit-text-stroke: 1px #F5A100;
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                    }

                                    .item-img {
                                        width: 30px;
                                        height: 30px;
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }

                        .turntable_btn {
                            position: absolute;
                            width: 65px;
                            height: 65px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 11;
                            cursor: pointer;

                            >img {
                                width: 100%;
                                height: 100%;
                            }

                            .count {
                                position: absolute;
                                z-index: 12;
                                font-weight: bold;
                                top: 50%;
                                left: 50%;
                                color: #9e652f;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }

                .share {
                    background: #17191f;
                    border-radius: .24rem;
                    padding: 12px 0;
                    margin-bottom: 16px;
                    padding: 12px;

                    .share_info {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #ffffff;
                        font-size: 15px;

                        >div {
                            cursor: pointer;
                        }

                        img {
                            width: 12px;
                            height: 13px;
                            margin-left: 7px;
                            cursor: pointer;
                        }
                    }

                    .share_btn {
                        width: max-content;
                        padding: 0 12px;
                        height: 41px;
                        line-height: 41px;
                        font-size: 18px;
                        font-weight: bold;
                        background: $bg-join-btn;
                        color: $font-btn-color;
                        border-radius: .24rem;
                        margin: 10px auto 0;
                        cursor: pointer;
                    }

                    .countDown {
                        color: #ffffff;
                        font-size: 16px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        margin: 7px 0 0;
                        row-gap: 5px;

                        &>span {
                            color: $font-sub-color;
                            display: inline-block;
                        }

                        &>span:last-child {
                            color: $font-sub-color;
                        }

                        ::v-deep .ant-statistic {
                            height: 24px;
                            line-height: 18px;

                            .ant-statistic-content {
                                height: 100%;
                            }
                        }

                        ::v-deep .ant-statistic-content-value {
                            color: #ffffff !important;
                            font-weight: 600;
                            font-size: 18px;
                            margin: 0 5px;
                            height: 100%;
                        }
                    }
                }

                .tabs {
                    margin: 15px 0;
                    background: #17191f;
                    border-radius: .24rem;

                    .tabs-header {
                        box-sizing: border-box;
                        font-size: 14px;
                        color: $font-sub-color;
                        margin: 0 auto;
                        position: relative;
                        z-index: 2;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 14px;
                        height: 45px;
                        line-height: 44px;

                        .tabs-header-item {
                            cursor: pointer;
                            padding: 0 10px;
                            border-radius: .24rem;
                            text-align: center;
                            flex: 1;

                            &.act {
                                height: 45px;
                                line-height: 44px;
                                color: #FFFFFF;
                                font-weight: bold;
                                position: relative;
                                color: #f3cc70;

                                &:after {
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-left: .1rem solid transparent;
                                    border-right: .1rem solid transparent;
                                    border-bottom: .1rem solid #f3cc70;
                                    position: absolute;
                                    bottom: .12rem;
                                    left: 50%;
                                    transform: translate(-50%, 100%);
                                }
                            }
                        }
                    }

                    .tabs-content {
                        border-radius: .24rem;
                        padding: 20px 14px;
                        padding-top: 0;

                        .tabs-content-inset {
                            border-radius: .24rem;
                        }

                        .tabs-content-item {
                            height: 311px;
                            overflow: hidden;
                            border-radius: .24rem;

                            &.records {
                                overflow: auto;
                                position: relative;

                                .last-tips {
                                    line-height: 31px;
                                    width: 100%;
                                    text-align: center;
                                    color: $font-sub-color;
                                    font-size: 14px;
                                }

                                .empty {
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    color: $font-sub-color;
                                    font-size: 16px;
                                    text-align: center;

                                    .empty_img {
                                        width: 170px;
                                        object-fit: contain;
                                    }
                                }
                            }

                            .tr {
                                height: 31px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                color: #ffffff;
                                font-size: 11px;

                                &:nth-child(2n+1) {
                                    background: #252831;
                                }

                                &:nth-child(2n) {
                                    // background: rgba(42, 44, 52, 0.9);
                                }

                                .td {
                                    text-align: center;
                                    width: 36%;
                                    white-space: nowrap;

                                    &:last-child {
                                        width: 28%;
                                    }

                                    &.money {
                                        color: #FFCE2D;
                                    }
                                }
                            }
                        }
                    }
                }

                .rule_box {
                    border: 2px solid $border-color;
                    color: $font-sub-color;
                    font-size: 12px;
                    text-align: left;
                    border-radius: .24rem;
                    padding: 10px;

                    .title {
                        font-size: 16px;
                        margin-bottom: 10px;
                        font-weight: bold;
                    }

                    ::v-deep img {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}
</style>
