import request from '@/common/http/request'
// 获取游戏分类带默认游戏厂商
export function getGameClassify(data) {
    const timestamp = new Date().getTime()
    return request({
        url: '/gameInfo/gameCategoryAndTitleList?timestamp=' + timestamp,
        method: 'post',
        data
    })
}
// 获取游戏厂商(带分页)
export function getGameFirmByPaging(data) {
    return request({
        url: '/gameInfo/getGameTitlePageByCategory',
        method: 'post',
        data
    })
}
// 获取游戏厂商
export function getGameFirm(data) {
    return request({
        url: '/gameInfo/getGameTitleByCategory',
        method: 'post',
        data
    })
}
// 分页查询游戏列表(标签)
export function getGameListByFirm(data) {
    return request({
        url: '/gameInfo/pageByTitle',
        method: 'post',
        data
    })
}
// 分页查询游戏列表(标签)
export function getSearchGameListByFirm(data) {
    return request({
        url: '/gameInfo/pageByNameTitle',
        method: 'post',
        data
    })
}
// 获取游戏列表
export function getGameList(data) {
    return request({
        url: '/gameInfo/pageByTitle',
        method: 'post',
        data
    })
}
// 获取游戏All根据分类(新)
export function getAllGameListByClassify(data) {
    return request({
        url: '/gameInfo/queryGameListByAllTitle',
        method: 'post',
        data
    })
}
// 游戏列表收藏(新)
export function getGameCollect(data) {
    return request({
        url: '/memberGameFav/pageGameInfoFavorites',
        method: 'post',
        data
    })
}
// 查询热门游戏
export function getHotGames(data) {
    return request({
        url: '/gameInfo/getHotGamePage',
        method: 'post',
        data
    })
}
// 分页查询游戏列表(标签)
export function getGamesByGameCategory(data) {
    const timestamp = new Date().getTime()
    return request({
        url: '/gameInfo/pageByGameCategory?timestamp=' + timestamp,
        method: 'post',
        data
    })
}
// 最近游戏记录
export function getRecentlyGames(data) {
    return request({
        url: '/gameHistory/pageGameHistory',
        method: 'post',
        data
    })
}
// 所有收藏游戏
export function getGameAllCollect(data) {
    const timestamp = new Date().getTime()
    return request({
        url: '/memberGameFav/listAllGameInfoFavorites?timestamp=' + timestamp,
        method: 'post',
        data
    })
}
// 添加收藏
export function addFav(data) {
    return request({
        url: '/memberGameFav/favorGame',
        method: 'post',
        data
    })
}
// 取消收藏
export function removeFav(data) {
    return request({
        url: '/memberGameFav/unFavorGame',
        method: 'post',
        data
    })
}
// 最近游戏记录
export function getGameUrl(data) {
    return request({
        url: '/gameInfo/gameAuthUrlByCanOrderState',
        method: 'post',
        data
    })
}
