import request from '@/common/http/request'

// 获取转盘活动信息
export function getTurntableInfo(data) {
    return request({
        url: '/memberLuckyWheel/info',
        method: 'post',
        data
    })
}
// 转盘抽奖
export function onTurntableDraw(data) {
    return request({
        url: '/memberLuckyWheel/prizeDraw',
        method: 'post',
        data
    })
}
// 转盘用户抽奖记录
export function getTurntableDrawRecords(data) {
    return request({
        url: '/memberLuckyWheel/logList',
        method: 'post',
        data
    })
}
