<template>
    <div class="pop">
        <van-popup v-model="show" position="bottom" @click-overlay="close">
            <div class="content">
                <div class="top_content">
                    <div class="left_box">
                        <img :src="info.imageUrl" alt="">
                    </div>
                    <div class="right_box">
                        <div class="title">
                            {{ info.gameName }}
                        </div>
                        <div class="platform">
                            {{ info.gameCategoryType }}
                        </div>
                        <div class="rank">
                            RTP:{{ info.rtp }}%
                        </div>
                    </div>
                </div>
                <div class="play_btn" @click.stop="onShowGamePopup(info)">
                    <i class="iconfont icon-shipin"></i>
                    {{ $t("lang.Play") }}
                </div>
                <div class="fav_icon" @click.stop="addCollect(info)">
                    <i v-if="activeIds.includes(info.id)" class="iconfont icon-a-shoucangyishoucang"></i>
                    <i v-else class="iconfont icon-a-shoucang_quxiaoshoucang"></i>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { Toast } from 'vant'
import {
    addFav,
    removeFav
} from '@/api/game'
export default {
    props: {
        showPop: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object,
            default: () => ({})
        },
        activeIds: {
            type: Array,
            default: () => { return [] }
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        }
    },
    watch: {
        showPop(newVal) {
            this.show = newVal
        }
    },
    data() {
        return {
            show: this.showPop,
            isDebounced: false
        }
    },
    mounted() { },
    methods: {
        close() {
            this.$emit('close')
        },
        async addCollect(item) {
            if (!this.isDebounced) {
                this.isDebounced = true
                const that = this
                if (that.activeIds.includes(item.id)) {
                    const index = that.activeIds.indexOf(item.id)
                    if (index !== -1) {
                        await removeFav({
                            id: item.id
                        })
                            .then((res) => {
                                if (res.code === 0) {
                                    that.$emit('activeIdsSplice', index)
                                    Toast(that.$t('home.CollectionCanceled'))
                                }
                            })
                    }
                } else {
                    await addFav({
                        id: item.id
                    })
                        .then((res) => {
                            if (res.code === 0) {
                                that.$emit('activeIdsPush', item.id)
                                Toast(that.$t('home.CollectionSucc'))
                            }
                        })
                }
                setTimeout(() => {
                    this.isDebounced = false
                }, 500)
            } else {
                console.log('111')
            }
        },
        onShowGamePopup(info) {
            this.$emit('onShowGamePopup', info)
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.pop {
    text-align: left;

    .van-popup {
        background: transparent;
    }

    .content {
        width: 100%;
        height: 100%;
        padding: .3rem .3rem .4rem;
        background: $bg-popup-color;
        border-radius: .24rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-sizing: border-box;
        position: relative;

        .top_content {
            display: flex;
            column-gap: .3rem;
            margin-bottom: .3rem;

            .left_box {
                border-radius: .24rem;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                overflow: hidden;

                img {
                    width: 2.78rem;
                    height: 2.78rem;
                }
            }

            .right_box {
                flex: 1;

                .title {
                    color: #ffffff;
                    font-size: .3rem;
                    font-weight: bold;
                    max-width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .platform {
                    font-size: .24rem;
                    color: $font-sub-color;
                    margin-top: .05rem;
                }

                .rank {
                    font-size: .24rem;
                    background: linear-gradient(90deg, #286aff, #00dbe9);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                }
            }
        }

        .play_btn {
            height: .8rem;
            line-height: .8rem;
            text-align: center;
            font-size: .3rem;
            color: $font-btn-color;
            font-weight: bold;
            border-radius: .24rem;
            background: $bg-btn-color;

            .icon-shipin {
                margin-right: .1rem;
            }
        }

        .fav_icon {
            position: absolute;
            top: .3rem;
            right: .3rem;

            .iconfont {
                font-size: .4rem;

                &.icon-a-shoucang_quxiaoshoucang {
                    color: $font-sub-color;
                }

                &.icon-a-shoucangyishoucang {
                    color: #ed1c24;
                }
            }
        }
    }
}
</style>
