<template>
    <div v-if="show" class="mask">
        <van-loading type="spinner" color="#1989fa" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            isLoading: true // 控制加载动画的显示
        }
    },
    computed: {
        ...mapState({
            isShowLoading: state => state.app.isShowLoading
        }),
        show: {
            get() {
                return this.isShowLoading === 1
            },
            set(val) {
                return val
            }
        }
    }
}
</script>

<style scoped lang="scss">
.mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999999;
    background: rgba(0, 0, 0, .5);
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
