import request from '@/common/http/request'

// 获取拼多多活动信息
export function getPinduoduoInfo(data) {
    return request({
        url: '/pinduoduo/getMemberInfoPinduoduoInfo',
        method: 'post',
        data
    })
}
// 用户拼多多抽奖记录列表
export function getPinduoduoRecordList(data) {
    return request({
        url: '/pinduoduo/getLotteriesRecordList',
        method: 'post',
        data
    })
}
// 用户拼多多抽奖
export function pinduoduoPrizeDraw(data) {
    return request({
        url: '/pinduoduo/memberInfoPrizeDraw',
        method: 'post',
        data
    })
}
// 用户拼多多抽奖提现
export function pinduoduoWithdraw(data) {
    return request({
        url: '/pinduoduo/lotteriesWithdraw',
        method: 'post',
        data
    })
}
