<template>
    <van-popup v-model="show" round position="bottom" @click-overlay="close">
        <div class="content">
            <i class="iconfont icon-cuowu_shibai" @click="close"></i>
            <img class="iosPwaImg" src="@/assets/downApp/iosPwaTips.png" @click.stop="close" />
        </div>
    </van-popup>
</template>

<script>
export default {
    data() {
        return {
            show: false
        }
    },
    props: {
        isToHome: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        open() {
            this.show = true
        },
        close() {
            this.show = false
            this.toHome()
        },
        toHome() {
            if (!this.isToHome) return
            if (this.$route.path.indexOf('/Home') !== -1) {
                window.location.reload()
            } else {
                this.onNavTo({ url: '/Home', replace: 1 })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.van-popup {
    background: transparent !important;
}

.content {
    box-sizing: border-box;
    width: 100%;
    background: transparent;
    padding-top: 0;
    text-align: right;

    &>i.iconfont {
        width: 35px;
        height: 35px;
        vertical-align: middle;
        // position: absolute;
        // right: 10px;
        // top: -58px;
        cursor: pointer;
        font-size: .62rem;
        color: #fff;
        margin-right: .24rem;
    }

    .iosPwaImg {
        width: 100%;
        height: auto;
        vertical-align: middle;
    }
}
</style>
