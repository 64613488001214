<template>
    <div class="popup">
        <van-popup :value="show" @click-overlay="close">
            <div class="icon-close" @click="close">
                <i class="iconfont icon-s-cuowu-guanbi"></i>
            </div>
            <div class="content">
                <div class="info">
                    <img class="logo" :src="logoUrl" @click.stop="refresh" />
                    <div class="title">{{ $t("downApp.ClickButtonToDownload2") }}</div>
                </div>
                <div class="btns">
                    <div @click="$refs.SaveVersionPopup.open()">
                        <div>{{ $t('downApp.QuickVersion') }}</div>
                        <div>(3.8M)</div>
                    </div>
                    <div @click="onDownApp(systemConfig.apkUrl)">
                        <div>{{ $t('downApp.NormalVersion') }}</div>
                        <div>(28.8M)</div>
                    </div>
                </div>
            </div>
        </van-popup>
        <SaveVersionPopup ref="SaveVersionPopup" :isToHome="false"></SaveVersionPopup>
    </div>
</template>

<script>
import SaveVersionPopup from '@/views/downAppPWA/modules/SaveVersionPopup'
import { getApkUrl } from '@/api/pwa'
export default {
    components: {
        SaveVersionPopup
    },
    data() {
        return {
            systemConfig: {}
        }
    },
    computed: {
        logoUrl() {
            return this.$store.getters.sysConfig?.homeLogoUrl
        },
        show: {
            get() {
                return this.$store.getters.isShowDownAppPopup
            },
            set(val) {
                return val
            }
        }
    },
    created() {
        getApkUrl().then((res) => {
            if (res.code) return
            this.systemConfig = res.result
        })
    },
    methods: {
        close() {
            this.$store.dispatch('user/setIsShowDownAppPopup', false)
        },
        onDownApp(url) {
            window.open(url)
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .icon-close {
        width: 28px;
        height: 28px;
        background: rgba(0, 0, 0, .4);
        border-radius: 50px;
        font-weight: 700;
        box-sizing: border-box;
        position: absolute;
        top: 10px;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        cursor: pointer;

        &>i {
            color: #fff;
            font-size: .22rem;
            vertical-align: middle;
        }
    }

    .content {
        border-radius: 12px;
        background: $bg-popup-color;
        width: 6rem;
        box-sizing: border-box;
        position: relative;
        overflow: auto;
        padding-bottom: .2rem;

        .info {
            padding: .28rem;

            .logo {
                width: 1.8rem;
                height: auto;
                margin: 0 auto;
                display: block;
                margin-bottom: .1rem;
            }

            .title {
                width: 100%;
                color: rgba(255, 255, 255, 0.85);
                line-height: .38rem;
                text-align: left;
                font-size: .36rem;
                margin-top: .08rem;
            }
        }

        .btns {
            height: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &>div {
                width: calc(50% - .3rem);
                height: .8rem;
                border-radius: .1rem;
                background: #286aff;
                color: #fff;
                text-align: center;
                line-height: .38rem;
                padding-top: .06rem;
                font-size: .28rem;
                cursor: pointer;

                &>div:last-child {
                    font-size: .22rem;
                    line-height: .28rem;
                }

                &:last-child {
                    margin-left: .2rem;
                }
            }
        }
    }
}
</style>
