const getters = {
    token: state => state.user.token,
    isShowDepositPopup: state => state.user.isShowDepositPopup,
    isShowWithdrawPopup: state => state.user.isShowWithdrawPopup,
    isShowBankSetPopup: state => state.user.isShowBankSetPopup,
    rememberInfo: state => state.user.rememberMe,
    userInfo: state => state.user.userInfo,
    loginType: state => state.user.loginType,
    isShowLoginPopup: state => state.user.isShowLoginPopup,
    sysConfig: state => state.system.sysConfig,
    Pixel: state => state.system.Pixel,
    TaId: state => state.system.TaId,
    ClickId: state => state.system.ClickId,
    language: state => state.system.language,
    invitCode: state => state.user.invitCode,
    invitCodeNum: state => state.user.invitCodeNum,
    isShowMyProfilePopup: state => state.user.isShowMyProfilePopup,
    isShowSettingPopup: state => state.user.isShowSettingPopup,
    isShowBetHistoryPopup: state => state.user.isShowBetHistoryPopup,
    isShowTransactionPopup: state => state.user.isShowTransactionPopup,
    isShowRedPacketPopup: state => state.user.isShowRedPacketPopup,
    isShowDownAppPopup: state => state.user.isShowDownAppPopup,
    isShowCheckInPopup: state => state.user.isShowCheckInPopup,
    isShowPendingPopup: state => state.user.isShowPendingPopup,
    isShowTurntablePopup: state => state.user.isShowTurntablePopup,
    isShowLossSupportPopup: state => state.user.isShowLossSupportPopup,
    isShowRebatePopup: state => state.user.isShowRebatePopup,
    isShowWinRankListPopup: state => state.user.isShowWinRankListPopup,
    isShowPinduodouPopup: state => state.user.isShowPinduodouPopup,
    isShowTreasureChestPopup: state => state.user.isShowTreasureChestPopup
}
export default getters
