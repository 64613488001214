import request from '@/common/http/request'

// 获取损失支持活动信息
export function getMemberEventLossInfo(data) {
    return request({
        url: '/reliefMoney/info',
        method: 'post',
        data
    })
}
// 会员每日会员下注返佣信息
export function getBettingRebateInfo(data) {
    return request({
        url: '/memberGameBetting/info',
        method: 'post',
        data
    })
}
// 用户损失支持活动奖励领取
export function getlossSupportReceive(data) {
    return request({
        url: '/member/lossSupportReceive',
        method: 'post',
        data
    })
}
