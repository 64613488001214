<template>
    <div class="MyRewards">
        <div class="dateBox">
            <div class="date" :id="'id' + index" v-for="(item, index) in dateRange" :key="item.id"
                :class="{ 'act': dateAct == item.id }" @click="changeTime(item.id)">
                {{ item.time }}
            </div>
        </div>
        <div class="MyRewards_list">
            <div class="scroll-list" scroll-y="true" @scrolltolower="getData">
                <div v-if="list.length" class="cards">
                    <div class="cards_items" v-for="(item, index) in list" :key="index">
                        <div class="time">{{ item.totalDate }}</div>
                        <div class="info">
                            <div class="left">
                                <div class="sort">{{ item.rankNumber }}</div>
                                <img mode="aspectFit" :src="userInfo.avatar || require('@/assets/person/avatar.avif')" />
                                <div class="baseInfo">
                                    <!-- <div>{{item.rankNumber}}</div> -->
                                    <div>{{ $t('winRankList.Reward') }}{{ CurrencyType + ' ' + item.rewardsAmt }}</div>
                                </div>
                            </div>
                            <div class="baseInfo">
                                <div>{{ $t('winRankList.TotalBets') }}</div>
                                <div>{{ CurrencyType }} {{ item.grandTotalBetting }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="loading" class="last-text">
                        <span>{{ $t('lang.Loading') }}</span>
                        <van-loading type="spinner" />
                    </div>
                    <div v-else-if="hasMore" class="last-text" @click="getData">
                        <span>{{ $t('lang.LoadMore') }}</span>
                        <i class="iconfont icon-xiaxia"></i>
                    </div>
                    <div v-else class="last-text">
                        <span>{{ $t('lang.AllLoaded') }}</span>
                    </div>
                </div>
                <div v-else class="no_data">
                    <div v-if="loading" class="loading">
                        <van-loading type="spinner" />
                        <span>{{ $t('lang.Loading') }}</span>
                    </div>
                    <div v-else class="empty">
                        <img src="@/assets/common/empty.png" />
                        <span>{{ $t('lang.empty') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getHistoryRankingList } from '@/api/winRankList.js'
export default {
    props: {
        rankingListTypeList: {
            type: Array,
            default: () => []
        },
        eventId: {
            type: String,
            default: ''
        },
        tabAct: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            dateAct: 0,
            dateRange: [],
            pageNo: 0,
            hasMore: true,
            loading: false,
            list: []
        }
    },
    watch: {
        rankingListTypeList(newVal, oldVal) {
            if (newVal && newVal.length) {
                const list = []
                newVal.forEach(x => {
                    if (x === 'everyday') {
                        const obj = {
                            id: list.length,
                            time: this.$t('winRankList.Daily'),
                            key: x
                        }
                        list.push(obj)
                    }
                    if (x === 'weekly') {
                        const obj = {
                            id: list.length,
                            time: this.$t('winRankList.Weekly'),
                            key: x
                        }
                        list.push(obj)
                    }
                    if (x === 'monthly') {
                        const obj = {
                            id: list.length,
                            time: this.$t('winRankList.Monthly'),
                            key: x
                        }
                        list.push(obj)
                    }
                })
                this.dateRange = list
                this.dateAct = 0
                this.pageNo = 0
                this.hasMore = true
                this.loading = false
                this.list = []
                // if (this.tabAct === 2) this.getData()
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    methods: {
        init() {
            this.dateAct = 0
            this.pageNo = 0
            this.hasMore = true
            this.loading = false
            this.list = []
            this.getData()
        },
        getData() {
            if (!this.hasMore) return
            this.loading = true
            this.pageNo++
            const params = {
                pageNo: this.pageNo,
                pageSize: 20,
                id: this.eventId,
                rankingListType: this.dateRange[this.dateAct].key
            }
            getHistoryRankingList(params).then((res) => {
                if (res.code) return
                const D = res.result
                this.list = this.list.concat(D.records)
                this.hasMore = D.total > this.list.length
            }).finally(() => {
                setTimeout(() => {
                    this.triggered = false
                    this.loading = false
                }, 200)
            })
        },
        changeTime(idx) {
            this.dateAct = idx
            this.pageNo = 0
            this.hasMore = true
            this.loading = false
            this.list = []
            this.getData()
        }
    }
}
</script>

<style scoped lang="scss">
.MyRewards {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.dateBox {
    padding: .07rem 0 .07rem .2rem;
    white-space: nowrap;
    background: #666D7C;
    border-bottom: .01rem solid #565D69;
    text-align: left;

    .date {
        margin-right: .1rem;
        display: inline-block;
        padding: 0 .16rem;
        font-size: .20rem;
        height: .5rem;
        line-height: .5rem;
        color: #A9ACAB;
        background: #060508;
        border: .01rem solid #5F5F65;
        border-radius: .06rem;
        white-space: nowrap;
        min-width: 1rem;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;

        &:last-child {
            margin-right: .5rem;
        }

        &.act {
            color: #01061A;
            border: .01rem solid #FFC627;
            background: #FFC627;
        }
    }
}

.MyRewards_list {
    flex: 1;
    width: 100%;
    overflow: hidden;
    padding-bottom: .01rem;
    position: relative;

    .scroll-list {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        box-sizing: border-box;
    }

    .cards {
        margin: .2rem .28rem;
        padding: 0 .24rem;
        padding-bottom: .2rem;
        border: .01rem solid #414148;
        border-radius: .2rem;
        background: #12121E;

        .cards_items {
            padding: .2rem;

            &:nth-child(2n+2) {
                background: #2D2D34;
                border-radius: .12rem;
            }

            .time {
                width: 100%;
                text-align: right;
                margin-bottom: .15rem;
                font-size: .2rem;
                color: #D0C7C0;
            }

            .info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                    display: flex;
                    align-items: center;

                    .sort {
                        color: #D0C7C0;
                        font-size: .52rem;
                    }

                    &>img {
                        width: .92rem;
                        height: .92rem;
                        border-radius: 50%;
                        border: .01rem solid #5F5F65;
                        margin: 0 .24rem;
                    }

                    .baseInfo {
                        &>div:last-child {
                            margin-top: 0;
                            white-space: nowrap;
                        }
                    }
                }

                .baseInfo {
                    &>div:first-child {
                        color: #D0C7C0;
                        font-size: .24rem;
                    }

                    &>div:last-child {
                        color: #F4BA7B;
                        font-size: .24rem;
                        margin-top: .12rem;
                        white-space: nowrap;
                    }
                }

                &>div:last-child {
                    text-align: right;
                }
            }
        }

    }

    .last-text {
        color: #7A787F;
        display: block;
        font-size: .22rem;
        margin-top: .01rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: .8rem;

        &>span {
            margin-right: .08rem;
        }

        &>i {
            font-size: .24rem;
            vertical-align: middle;
        }
    }

    .no_data {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .24rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        .loading {
            &>span:last-child {
                display: inline-block;
                margin-top: .2rem;
            }
        }

        .empty {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &>img {
                width: 3rem;
                height: auto;
            }
        }
        span {
            line-height: .8rem;
        }
    }
}</style>
