<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <statusBar></statusBar>
                <div class="header">
                    <div>{{ $t("winRankList.RankingList") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="tab">
                    <div class="tab__item" :class="{ 'act': item.id === tabAct }" v-for="(item) in tabList"
                        :key="item.id" @click="changeTab(item)">
                        <!-- <i class="iconfont " :class="[item.icon]"></i> -->
                        <span>{{ item.name }}</span>
                    </div>
                </div>
                <div class="box">
                    <Ranking ref="Ranking" :rankingListTypeList="rankingListTypeList" :eventId="eventId"
                        v-show="tabAct == 0"></Ranking>
                    <Rules ref="Rules" :eventId="eventId" v-show="tabAct == 1"></Rules>
                    <MyRewards ref="MyRewards" :rankingListTypeList="rankingListTypeList" :eventId="eventId"
                        :tabAct="tabAct" v-show="tabAct == 2"></MyRewards>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import statusBar from '@/components/statusBar'
import Ranking from './modules/Ranking.vue'
import MyRewards from './modules/MyRewards.vue'
import { getRankRulesList } from '@/api/winRankList.js'
import Rules from './modules/Rules.vue'
export default {
    name: 'winRankList',
    components: { statusBar, Ranking, Rules, MyRewards },
    data() {
        return {
            tabAct: 0,
            tabList: [
                {
                    id: 0,
                    name: this.$t('winRankList.Ranking')
                },
                {
                    id: 1,
                    name: this.$t('winRankList.Rules')
                },
                {
                    id: 2,
                    name: this.$t('winRankList.MyRewards')
                }
            ],
            rankingListTypeList: []
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        eventId() {
            return this.$store.state.user.WinRankListEventId
        },
        show: {
            get() {
                return this.$store.getters.isShowWinRankListPopup
            },
            set(val) {
                return val
            }
        }
    },
    created() {
    },
    methods: {
        open() {
            this.tabAct = 0
            const item = this.tabList[this.tabAct]
            this.changeTab(item)
            getRankRulesList({ id: this.eventId }).then((res) => {
                if (res.code) return
                const D = res.result
                this.rankingListTypeList = D.rankingListTypeList || []
            })
            this.tabList = [
                {
                    id: 0,
                    name: this.$t('winRankList.Ranking')
                },
                {
                    id: 1,
                    name: this.$t('winRankList.Rules')
                },
                {
                    id: 2,
                    name: this.$t('winRankList.MyRewards')
                }
            ]
            // if (this.firstLoaded) {
            //     this.init()
            // }
        },
        close() {
            this.$store.dispatch('user/setIsShowWinRankListPopup', { id: '', show: false })
        },
        changeTab(item) {
            // let lastTabAct = this.tabAct
            this.$nextTick(() => {
                if (item.id === 0) {
                    this.tabAct = item.id
                    // this.$refs.Ranking.init()
                }
                if (item.id === 1) {
                    this.tabAct = item.id
                    this.$refs.Rules.init()
                }
                if (item.id === 2) {
                    this.onNavToByIsLogin({}).then(() => {
                        this.tabAct = item.id
                        this.$nextTick(() => {
                            this.$refs.MyRewards.init()
                        })
                    })
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {

        .van-popup {
            &.van-popup--center {
                width: 100% !important;
                height: 100% !important;
                max-width: 100% !important;
                min-height: 100% !important;
                max-height: 100% !important;
            }

            .content {
                width: 100% !important;
                // padding-bottom: .4rem;
                height: 100% !important;

                .header {
                    height: .94rem;
                    padding: 0 .28rem;

                    &>div:first-child {
                        font-size: .3rem;
                    }

                    .close {
                        font-size: .3rem;
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: #516382;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .tab {
                height: .76rem;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                z-index: 3;
                cursor: pointer;
                border-bottom: .01rem solid #e5e7eb;
                margin-top: .1rem;
                padding: 0 .24rem;

                &__item {
                    text-align: center;
                    color: #B1BAD3;
                    font-size: .24rem;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    line-height: .3rem;
                    margin-right: .48rem;
                    position: relative;
                    text-align: left;
                    height: 100%;

                    &>i {
                        margin-right: .15rem;
                        font-size: .34rem;
                    }

                    &.act {
                        color: #ff7300;

                        &:after {
                            content: '';
                            width: 100%;
                            height: .04rem;
                            background-color: #ff7300;
                            position: absolute;
                            bottom: -.02rem;
                            left: 0;
                        }
                    }
                }
            }

            .box {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;
            }
        }
    }
}
</style>
