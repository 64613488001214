<template>
    <div>
        <div class="main_content">
            <div class="WP_form">
                <div class="info_title" style="margin-top: 0upx;margin-bottom: 6upx;">{{ $t('bankset.Type') }}</div>
                <div class="info_radio">
                    <van-radio-group v-model="formData.bankType" direction="horizontal">
                        <van-radio :name="item.value" checked-color="#1989fa" v-for="(item, index) in columns"
                            :key="index">{{ item.label }}</van-radio>
                    </van-radio-group>
                </div>
                <!-- 收款人名称 -->
                <div class="info_title">{{ $t('bankset.AccountName') }}</div>
                <div class="info_input">
                    <a-input v-model="formData.accountName" :placeholder="$t('bankset.NameOfTheAccountHolder')"
                        allowClear></a-input>
                </div>
                <!-- PIX账号 -->
                <div class="info_title">{{ $t('bankset.PIXAccount') }}</div>
                <div class="info_input">
                    <a-input v-model="formData.pixAccount" :placeholder="$t('bankset.InputPIXAccount')"
                        allowClear></a-input>
                </div>
                <!-- 收款人账号 -->
                <div class="info_title">{{ $t('bankset.CPFAccount') }}</div>
                <div class="info_input">
                    <a-input v-model="formData.cpfAccount" :placeholder="$t('bankset.PleaseEnterCPF')" allowClear></a-input>
                </div>
                <div class="info_title">{{ $t('bankset.WithdrawPassword') }}</div>
                <div class="info_input">
                    <a-input-password v-model="formData.withdrawPassword"
                        :placeholder="$t('bankset.InputWithdrawalPassword')" autocomplete="new-password"
                        allowClear></a-input-password>
                </div>
                <div class="info_title">{{ $t('bankset.ConfirmWithdrawalPassword') }}</div>
                <div class="info_input">
                    <a-input-password v-model="formData.withdrawPasswordVerify"
                        :placeholder="$t('bankset.InputConfirmWithdrawalPassword')" autocomplete="new-password"
                        allowClear></a-input-password>
                </div>
            </div>
            <div class="action_btn" @click="handleSubmit">
                <span>{{ $t('bankset.Save') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant'
import { deepClone } from '@/utils'
import {
    saveBankBrazil,
    getBankBrazil,
    updateBankBrazil
} from '@/api/withdraw'
import md5 from 'md5'
export default {
    name: 'bankSetBrazil',
    data() {
        return {
            columns: [
                { value: 'CPF', label: 'CPF' },
                { value: 'CNPJ', label: 'CNPJ' },
                { value: 'EMAIL', label: 'EMAIL' },
                { value: 'EVP', label: 'EVP' },
                { value: 'PHONE', label: 'PHONE' },
                { value: 'RANDOM', label: 'RANDOM' }
            ],
            formData: {
                bankType: 'CPF',
                accountName: '',
                pixAccount: '',
                cpfAccount: '',
                withdrawPassword: '',
                withdrawPasswordVerify: ''
            }
        }
    },
    created() { },
    methods: {
        close() {
            this.$emit('close')
        },
        open() {
            getBankBrazil({}, {
                custom: {
                    toast: false
                }
            }).then(res => {
                if (res.code) return
                this.formData = {
                    ...res.result,
                    withdrawPassword: ''
                }
            })
            this.$nextTick(() => { })
        },
        async handleSubmit() {
            if (!this.formData.bankType) {
                Toast(this.$t('bankset.ChooseCertificateType'))
                return
            }
            if (!this.formData.accountName) {
                Toast(this.$t('bankset.InputAccountName'))
                return
            }
            if (!this.formData.pixAccount) {
                Toast(this.$t('bankset.InputPIXAccount'))
                return
            }
            if (!this.formData.cpfAccount) {
                Toast(this.$t('bankset.PleaseEnterCPF'))
                return
            }
            if (!this.formData.withdrawPasswordVerify) {
                Toast(this.$t('bankset.InputConfirmWithdrawalPassword'))
                return
            }
            if (this.formData.withdrawPassword !== this.formData.withdrawPasswordVerify) {
                Toast(this.$t('bankset.InputInconsistentWithdrawPassword'))
                return
            }
            const form = deepClone(this.formData)
            if (form.withdrawPassword) form.withdrawPassword = md5(form.withdrawPassword)
            if (form.withdrawPasswordVerify) form.withdrawPasswordVerify = md5(form.withdrawPasswordVerify)
            let result
            if (form.id) {
                result = await updateBankBrazil(form)
            } else {
                result = await saveBankBrazil(form)
            }
            Toast(result.message)
            if (result.code) return
            this.$emit('succ')
            this.close()
        }
    }
}
</script>

<style scoped lang="scss">
.main_content {
    overflow: auto;

    .WP_form {
        padding: 0 .28rem;

        .info_title {
            margin-bottom: .18rem;
            font-size: .28rem;
            color: #FFFFFF;
            margin-top: .24rem;
            text-align: left;
        }

        .info_radio {
            width: 100%;
            font-size: .26rem;
            font-weight: 400;
            padding-right: .28rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            ::v-deep .van-radio {
                margin-bottom: .18rem;

                .van-radio__label {
                    color: #516382;
                }

                .van-radio__icon--checked {
                    &+.van-radio__label {
                        color: #fff;
                    }
                }
            }
        }

        .info_input {
            ::v-deep input::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }

            ::v-deep input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }

            ::v-deep input[type='number'] {
                -moz-appearance: textfield;
            }

            ::v-deep .ant-input-suffix {
                .ant-input-clear-icon {
                    color: #666;
                    font-size: .32rem;
                    margin-right: .1rem;
                }
            }

            ::v-deep .ant-input-password {
                .ant-input {
                    padding-right: 1rem;
                }

                .ant-input-suffix {
                    .ant-input-password-icon {
                        margin-right: .1rem;
                        font-size: .3rem;
                        color: #516382;
                    }
                }
            }

            ::v-deep .ant-input {
                background-color: #000 !important;
                border-radius: 0.14rem;
                font-size: 0.22rem;
                height: .8rem;
                padding: 0px 0.24rem !important;
                color: #e3e3e3;
                border: .02rem solid #383e4b !important;
                padding-left: .28rem !important;

                &:focus {
                    background-color: transparent;
                    border-color: #286aff !important;
                }

                &::-webkit-input-placeholder {
                    /* WebKit browsers */
                    font-size: .22rem;
                    color: #516382;
                    opacity: .7;
                    font-weight: 100;
                }

                &:-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    font-size: .22rem;
                    color: #516382;
                }

                &::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    font-size: .22rem;
                    color: #516382;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10+ */
                    font-size: .22rem;
                    color: #516382;
                }
            }
        }
    }

    .action_btn {
        width: 6.94rem;
        height: .7rem;
        border-radius: .15rem;
        margin: .3rem auto;
        margin-top: .58rem;
        font-family: eryawenrunti;
        font-weight: 600;
        font-size: .28rem;
        color: #fff;
        text-align: center;
        line-height: .7rem;
        cursor: pointer;
        background-color: #286aff;

        &.disabled {
            background: #9D9E9F;
            color: rgba(255, 255, 255, 0.45);
            cursor: not-allowed;
            box-shadow: 0 .06rem 0 0 #2D3241, 0 .02rem .03rem 0 #333, 0 .02rem .03rem 0 #999;
        }
    }
}
</style>
