<template>
    <div id="fixedHeader" class="fixed-header">
        <statusBar></statusBar>
        <div class="pages-header">
            <div @click="onNavBack">
                <i class="iconfont icon-xiangzuo"></i>
                <!-- <img class="logo" :src="logoUrl" @click.stop="toHome" /> -->
                <img v-if="device == 'mobile'" class="logo" style="height: .75rem;" :src="logoUrl2" @click.stop="toHome" />
                <img v-else class="logo" :src="logoUrl" @click.stop="toHome" />
            </div>
            <div class="user_action">
                <div></div>
                <div class="user_info">
                    <!-- <div class="CurrencyType"><span>{{ CurrencyType }}</span></div> -->
                    <!-- <div>
                        <div class="user_money">
                            <span style="margin-left: 4px;">{{ userInfo && userInfo.balanceAmtAccount ?
                                userInfo.balanceAmtAccount.useBalance.toFixed(1) : "0.0" }}</span>
                        </div>
                        <div class="user_account">
                            <span style="margin-left: 4px;">{{ userInfo && userInfo.bonusAmtAccount ?
                                userInfo.bonusAmtAccount.useBalance.toFixed(1) : "0.0" }}</span>
                        </div>
                    </div> -->
                    <div class="deposit deposit_app" @click.stop="toDeposit">+</div>
                    <div class="deposit deposit_PC" @click.stop="toDeposit">{{ $t("deposit.Deposit") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import statusBar from '@/components/statusBar'
import { mapState } from 'vuex'
export default {
    name: 'gameHeader',
    components: { statusBar },
    data() {
        return {}
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        logoUrl() {
            return this.$store.getters.sysConfig?.homeLogoUrl
        },
        logoUrl2() {
            return this.$store.getters.sysConfig?.homeImageUrl
        },
        userInfo() {
            return this.$store.getters.userInfo
        }
    },
    created() { },
    methods: {
        onNavBack() {
            this.$emit('onNavBack')
        },
        toHome() {
            this.$emit('toHome')
        },
        toDeposit() {
            this.$store.dispatch('user/setIsShowDepositPopup', true)
        }
    }
}
</script>

<style scoped lang="scss">
.fixed-header {
    width: 100%;
    position: relative;
    z-index: 98;
    box-shadow: 0 4px 20px #0006;
    background: linear-gradient(270deg, #1d2027, #1b2746);

    .pages-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        padding-right: .2rem;
        height: 1rem;
        padding-right: .2rem;
        box-sizing: border-box;
        position: relative;
        padding-left: 260px;

        &>div:first-child {
            display: flex;
            align-items: center;
            padding-left: .2rem;
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 3;

            &>i {
                color: #516382;
                font-size: .48rem;
                margin-right: .14rem;
            }

            .logo {
                height: .48rem;
                width: auto;
                will-change: auto !important;
                cursor: pointer;
            }
        }

        .user_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            z-index: 2;
            margin: 0 auto;
            max-width: 1414px;
            min-width: 720px;
            padding-left: 32px;
            padding-right: 32px;
            width: 100%;

            .user_info {
                display: flex;
                align-items: center;
                // column-gap: 10px;
                font-size: .24rem;
                background: #15161c;
                // padding: .01rem .15rem;
                // padding-right: .08rem;
                border-radius: .1rem;

                .CurrencyType {
                    background: rgba(24, 171, 125, 1);
                    padding: .02rem;
                    border-radius: 50%;
                    flex-shrink: 0;
                    width: .36rem;
                    height: .36rem;
                    text-align: center;
                    line-height: .36rem;
                    color: #fff;
                    font-size: .23rem;
                }

                .user_account,
                .user_money {
                    // display: flex;
                    // align-items: center;
                    color: #FFFFFF;
                    // background: rgba(255, 255, 255, 0.15);
                    padding: 0 .08rem;
                    border-radius: .2rem;
                }

                .user_account {
                    color: #afc2e4;
                }

                .icon-shuaxin {
                    font-size: .2rem;
                    color: #286aff;
                }

                .deposit {
                    height: .6rem;
                    border-radius: .17rem;
                    background: #ff7300;
                    color: #fff;
                    line-height: .6rem;
                    font-size: .5rem;
                    // margin-left: .1rem;

                    &.deposit_app {
                        display: none;
                        width: .6rem;
                    }

                    &.deposit_PC {
                        font-size: .25rem;
                        font-weight: bold;
                        padding: 0 .3rem;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

html[data-device-nodesktop="1"] {
    .fixed-header {
        z-index: 100;

        .downApp {
            display: none;
        }
    }
}
</style>
