import request from '@/common/http/request'

// 获取累计充值活动信息
export function getTotalRechargeInfo(data) {
    return request({
        url: '/totalRecharge/getMemberTotalRechargeGetInfo',
        method: 'post',
        data
    })
}
// 获取充值赠金活动规则
export function getRechargeGiftInfo(data) {
    return request({
        url: '/rechargeGift/getRechargeGiftInfo',
        method: 'post',
        data
    })
}

// 获取Usdt充值赠金活动规则
export function getUsdtRechargeGiftInfo(data) {
    return request({
        url: '/usdtRechargeGift/getUsdtRechargeGiftInfo',
        method: 'post',
        data
    })
}
