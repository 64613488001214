<template>
    <div>
        <van-popup v-model="show">
            <div class="gamePages">
                <gameHeader @onNavBack="onNavBack" @toHome="toHome"></gameHeader>
                <div v-if="show" class="main-content">
                    <iframe v-if="isUrl && gameUrl" id="myIframe1" :src="gameUrl" frameborder="0"
                        allowfullscreen="allowfullscreen" webkitallowfullscreen="true" mozallowfullscreen="true"
                        style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 999"></iframe>
                    <iframe v-else id="myIframe2" frameborder="0" allowfullscreen="allowfullscreen"
                        webkitallowfullscreen="true" mozallowfullscreen="true"
                        style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 999"></iframe>
                </div>
            </div>
        </van-popup>
        <gameOut ref="gameOut" @gameOut="close"></gameOut>
    </div>
</template>

<script>
import { getGameUrl } from '@/api/game'
import { getUserInfo } from '@/api/user'
import gameHeader from './gameHeader.vue'
import gameOut from './gameOut.vue'
export default {
    components: { gameHeader, gameOut },
    data() {
        return {
            show: false,
            isUrl: true,
            gameUrl: '',
            id: '',
            gameInfoId: '',
            gamePlatformType: 'PG',
            providerType: ''
        }
    },
    props: {
        params: {
            type: Object,
            default: () => {
                return {
                    id: '',
                    gameInfoId: '',
                    gamePlatformType: '',
                    providerType: ''
                }
            }
        }
    },
    methods: {
        open() {
            this.show = true
            this.isUrl = true
            this.gameUrl = ''
            this.$nextTick(() => {
                this.gameInfoId = this.params.gameInfoId
                this.id = this.params.id
                this.gamePlatformType = this.params.gamePlatformType
                this.providerType = this.params.providerType
                this.getGameUrl()
            })
        },
        close() {
            this.gameInfoId = ''
            this.id = ''
            this.gamePlatformType = ''
            this.providerType = ''
            this.gameUrl = ''
            getUserInfo()
            this.show = false
        },
        onNavBack() {
            console.log(6666)
            this.$refs.gameOut.open()
        },
        toHome() {
            this.close()
            if (this.$route.path.indexOf('/Home') !== -1) {
                window.location.reload()
            } else {
                this.onNavTo({ url: '/Home', replace: 1 })
            }
        },
        async getGameUrl() {
            const { result, code } = await getGameUrl({
                gameInfoId: this.gameInfoId,
                gameId: this.id,
                gamePlatformType: this.gamePlatformType,
                providerType: this.providerType
            })
            if (code === 0) {
                if (result.indexOf('<!doctype html>') !== -1) {
                    this.nodes = result
                    this.isUrl = false

                    if (window.PGSoftInterface !== undefined) {
                        window.PGSoftInterface.loadGame(result)
                    } else {
                        const iframe = document.getElementById('myIframe2')
                        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
                        iframeDoc.open()
                        iframeDoc.write(result)
                    }
                } else {
                    this.gameUrl = result
                    this.isUrl = true
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.icon-home {
    width: .58rem;
    height: .58rem;
    cursor: pointer;
}

.icon-recharge {
    width: .6rem;
    height: .6rem;
    cursor: pointer;
    margin-right: .1rem;
}

.van-popup {
    &.van-popup--center {
        top: 0;
        left: 0;
        transform: translate3d(0, 0, 0);
        width: 100% !important;
        height: 100% !important;
    }
}

.gamePages {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #15161c;

    .main-content {
        flex: 1;
        position: relative;
        width: 100%;

        iframe {
            overflow: hidden;
            /* 隐藏滚动条 */
        }
    }

    .status_bar {
        height: 0;
        width: 100%;
    }

    .webview_back {
        user-select: none;
        position: fixed;
        top: .4rem;
        width: 1.68rem;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 99999;
        background: #efefef;
    }

    .back_box {
        height: .6rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        z-index: 99;

        .back_arrow {
            width: .4rem;
            height: .4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            image {
                width: .3rem;
                height: .3rem;
            }
        }

        .back_fav {
            width: .4rem;
            height: .4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            image {
                width: .24rem;
                height: .24rem;
            }
        }
    }
}
</style>
