import request from '@/common/http/request'

// 获取可领取的待办事项
export function getNotication(data) {
    return request({
        url: '/memberInfoMessage/getList',
        method: 'post',
        data
    })
}

// 文章详情列表
export function getArticleList(params) {
    return request({
        url: '/articleManage/getListByType/' + params.id,
        method: 'get',
        params
    })
}

// 根据id 查看消息明细
export function getMsgDetails(data) {
    return request({
        url: '/memberInfoMessage/getMessageItemById',
        method: 'post',
        data
    })
}
