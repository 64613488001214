<template>
    <div class="status-bar" :style="{'height': statusBarHeight + 'px'}"></div>
</template>

<script>
export default {
    name: 'status-bar',
    data() {
        return {
            statusBarHeight: 0
        }
    },
    mounted() {
        this.statusBarHeight = this.getStatusBarHeight()
    },
    methods: {
        getStatusBarHeight() {
            let statusBarHeight = this.statusBarHeight
            if (window.plus) {
                statusBarHeight = plus.navigator.getStatusBarHeight()
            }
            return statusBarHeight
        }
    }
}
</script>

<style lang="scss" scoped>
.status-bar{
    width: 100%;
}
</style>
