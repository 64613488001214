import request from '@/common/http/request'

// 会员每日签到
export function onDailyCheckIn(data) {
    return request({
        url: '/member/DailyCheckIn',
        method: 'post',
        data
    })
}
// 会员每日签到信息
export function getDailyCheckInInfo(data) {
    return request({
        url: '/memberSingIn/info',
        method: 'post',
        data
    })
}
