<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close">
            <div class="content">
                <statusBar></statusBar>
                <div class="header">
                    <div>{{ $t("Setting.changePwd") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <a-form-model ref="ruleForm" :model="form" :rules="rules" @submit.native.prevent>
                        <a-form-model-item label="" prop="oldPassword">
                            <a-input-password v-model="form.oldPassword" :placeholder="$t('Setting.oldPasswordTips')"
                                autocomplete="new-password" allowClear>
                                <i slot="prefix" class="iconfont icon-mima" />
                            </a-input-password>
                        </a-form-model-item>
                        <a-form-model-item label="" prop="newPassword">
                            <a-input-password v-model="form.newPassword" :placeholder="$t('Setting.passwordTips')"
                                autocomplete="new-password" allowClear>
                                <i slot="prefix" class="iconfont icon-mima" />
                            </a-input-password>
                        </a-form-model-item>
                        <a-form-model-item label="" prop="mathPassword">
                            <a-input-password v-model="form.mathPassword" :placeholder="$t('Setting.passwordTips2')"
                                autocomplete="new-password" allowClear>
                                <i slot="prefix" class="iconfont icon-mima" />
                            </a-input-password>
                        </a-form-model-item>
                    </a-form-model>
                    <a-button class="btn" @click="handleSubmit">{{ $t("Setting.changePwd") }}</a-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import statusBar from '@/components/statusBar'
import md5 from 'md5'
import { resetPwd, logout } from '@/api/user'
import { Toast } from 'vant'

export default {
    name: 'changePwd',
    components: { statusBar },
    data() {
        const validatePass2 = (rule, value, callback) => {
            const reg = /^(?=.*[a-zA-Z])[a-zA-Z0-9\W_]{8,16}$/g
            if (!reg.test(value)) {
                callback(new Error(this.$t('login.PasswordFormat')))
            } else {
                callback()
            }
        }
        return {
            show: false,
            form: {
                oldPassword: '',
                newPassword: '',
                mathPassword: ''
            },
            rules: {
                oldPassword: [
                    { required: true, message: this.$t('Setting.PleaseEnterYourOriginalPassword'), trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: this.$t('Setting.PleaseEnterYourNewPassword'), trigger: 'blur' },
                    { validator: validatePass2, trigger: 'blur' }
                ],
                mathPassword: [
                    { required: true, message: this.$t('Setting.PleaseEnterYourNewPasswordSure'), trigger: 'blur' }
                ]
            },
            debounceTimer: null
        }
    },
    computed: {
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        }
    },
    created() {
    },
    methods: {
        open() {
            this.show = true
        },
        close() {
            this.show = false
            this.form = {
                oldPassword: '',
                newPassword: '',
                mathPassword: ''
            }
            this.$refs.ruleForm.clearValidate()
        },
        handleSubmit() {
            const that = this
            clearTimeout(that.debounceTimer)
            that.debounceTimer = setTimeout(() => {
                that.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        const params = {
                            passwordOld: md5(that.form.oldPassword),
                            password: md5(that.form.newPassword),
                            passwordSure: md5(that.form.mathPassword)
                        }
                        resetPwd(params).then(res => {
                            if (!res) return
                            Toast(res.message)
                            if (res.code === 0) {
                                logout().then((res) => {
                                    if (res.code) return
                                    that.$websocket.close()
                                    that.close()
                                    that.$store.dispatch('user/setIsShowSettingPopup', false)
                                    that.$store.commit('user/SET_TOKEN', '')
                                    if (that.$route.path.indexOf('/Home') !== -1) {
                                        that.$router.go(0)
                                    } else {
                                        that.onNavTo({ url: '/Home', replace: 1 })
                                    }
                                })
                            }
                        })
                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            }, 300)
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {

        .van-popup {
            &.van-popup--center {
                width: 100% !important;
                height: 100% !important;
                max-width: 100% !important;
                min-height: 100% !important;
                max-height: 100% !important;
            }

            .content {
                width: 100% !important;
                // padding-bottom: .4rem;
                height: 100% !important;

                .header {
                    height: .94rem;
                    padding: 0 .28rem;

                    &>div:first-child {
                        font-size: .3rem;
                    }

                    .close {
                        font-size: .3rem;
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-popup-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                }

                .close {
                    color: #516382;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 30px 40px;

                ::v-deep .ant-form {
                    .ant-form-item {
                        margin-bottom: .43rem;

                        &.ant-form-item-with-help {
                            margin-bottom: 2.4px;
                        }

                        .language {
                            width: auto;
                            height: auto;
                            padding-right: .12rem;
                            border-right: .01rem solid #2a2f39;
                        }
                    }

                    .has-error {
                        .ant-input-prefix {
                            .iconfont {
                                position: relative;

                                &:after {
                                    content: '*';
                                    position: absolute;
                                    color: #EA4E3D;
                                    top: .02rem;
                                    right: -.1.5rem;
                                    font-size: .24rem;
                                }
                            }
                        }

                        .ant-form-explain {
                            height: .44rem;
                            line-height: .2rem;
                            font-size: 0.18rem;
                            padding: .02rem 0;
                            text-align: left;

                            &:not(.show-help-leave)::before {
                                background-color: #EA4E3D;
                                border-radius: 50%;
                                content: "";
                                height: 0.18rem;
                                margin-right: 0.1rem;
                                visibility: visible;
                                width: 0.18rem;
                                display: inline-block;
                                background-image: url('@/assets/icons.png');
                                background-position: -13.509rem -4.59rem;
                                background-size: 13.689rem 13.419rem;
                            }
                        }
                    }
                }

                ::v-deep .ant-input-prefix {
                    left: .14rem;
                    color: #516382;

                    .icon-person,
                    .icon-mima {
                        font-size: .37rem;
                    }

                    .icon-mima {
                        font-size: .34rem;
                    }

                    .icon-open {
                        font-size: .2rem;
                    }

                    .icon-key-fill {
                        font-size: .34rem;
                    }

                    .icon-shebeizhuce {
                        font-size: .34rem;
                    }

                    .flag {
                        width: .28rem;
                        height: 0.22rem;
                        margin-right: .1rem;
                    }

                    span {
                        font-size: .23rem;
                        // margin-right: .1rem;
                        padding-right: .15rem;
                        color: #fff;
                        border-right: .01rem solid $border-color;
                    }
                }

                ::v-deep .ant-input {
                    background-color: #000 !important;
                    border-radius: 0.14rem;
                    font-size: 0.22rem;
                    height: 0.85rem;
                    padding: 0px 0.65rem !important;
                    color: #e3e3e3;
                    border: .02rem solid #383e4b !important;

                    &:focus {
                        background-color: transparent;
                        border-color: $border-active-color !important;
                    }

                    &::-webkit-input-placeholder {
                        /* WebKit browsers */
                        font-size: .22rem;
                        color: #516382;
                        opacity: .7;
                        font-weight: 100;
                    }

                    &:-moz-placeholder {
                        /* Mozilla Firefox 4 to 18 */
                        font-size: .22rem;
                        color: #333;
                    }

                    &::-moz-placeholder {
                        /* Mozilla Firefox 19+ */
                        font-size: .22rem;
                        color: #333;
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10+ */
                        font-size: .22rem;
                        color: #333;
                    }
                }

                ::v-deep .ant-input-suffix {
                    .ant-input-clear-icon {
                        color: #666;
                        font-size: .32rem;
                    }
                }

                .ant-input-affix-wrapper {
                    &.ant-input-password {
                        ::v-deep .ant-input {
                            padding-right: 1rem;
                        }

                        ::v-deep .ant-input-suffix {
                            .ant-input-password-icon {
                                margin-right: .1rem;
                                font-size: .3rem;
                                color: #516382;
                            }
                        }
                    }
                }

                .btn {
                    width: 100%;
                    font-size: .24rem;
                    height: .65rem;
                    text-align: center;
                    line-height: .65rem;
                    background: $bg-btn-color;
                    color: $font-btn-color;
                    font-weight: bold;
                    border-radius: .14rem;
                    margin: .18rem 0;
                    cursor: pointer;

                    &.ant-btn {
                        border-color: transparent;
                    }
                }
            }
        }
    }
}
</style>
