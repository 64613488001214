import request from '@/common/http/request'

// 获取可领取的待办事项
export function getClaimableInfo(data) {
    return request({
        url: '/memberMatter/claimableInfo',
        method: 'post',
        data
    })
}
// 获取历史待办事项
export function getHistoryInfo(data) {
    return request({
        url: '/memberMatter/historyInfo',
        method: 'post',
        data
    })
}
// 代办事项领取奖励
export function getAward(data) {
    return request({
        url: '/memberMatter/matterAwardCollection',
        method: 'post',
        data
    })
}
