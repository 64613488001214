<template>
    <div class="Rules">
        <div class="title">{{ $t('winRankList.ActivityAward') }}</div>
        <div class="table">
            <div class="tHeader">
                <div>{{ $t('winRankList.Rank') }}</div>
                <div v-if="everyday.length">{{ $t('winRankList.Daily') }}</div>
                <div v-if="weekly.length">{{ $t('winRankList.WeeklyList') }}</div>
                <div v-if="monthly.length">{{ $t('winRankList.MonthlyList') }}</div>
            </div>
            <van-list class="tBody" :finished="!hasMore2">
                <div v-if="list2.length">
                    <div class="tr" v-for="(item, index) in list2" :key="index">
                        <div>{{ item.whichRank }}</div>
                        <div v-if="everyday.length">{{item.everydayPrizePoolRewardsType == 'SCALE' ? (item.everydayRewardRatio + '%') : (CurrencyType + ' ' + item.everydayRewardAmt)}}</div>
                        <div v-if="weekly.length">{{item.weeklyPrizePoolRewardsType == 'SCALE' ? (item.weeklyRewardRatio + '%') : (CurrencyType + ' ' + item.weeklyRewardAmt)}}</div>
                        <div v-if="monthly.length">{{item.monthlyPrizePoolRewardsType == 'SCALE' ? (item.monthlyRewardRatio + '%') : (CurrencyType + ' ' + item.monthlyRewardAmt)}}</div>
                    </div>
                </div>
                <div v-else class="nodata">
                    <img src="@/assets/common/empty.png" mode="widthFix" />
                    <div class="empty">{{ $t("lang.empty") }}</div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import { getRankRulesList } from '@/api/winRankList.js'
export default {
    data() {
        return {
            pageNo2: 0,
            hasMore2: true,
            loading2: false,
            list2: [],
            everyday: [],
            weekly: [],
            monthly: []
        }
    },
    props: {
        eventId: {
            type: String,
            default: ''
        }
    },
    methods: {
        init() {
            this.pageNo2 = 0
            this.hasMore2 = true
            this.loading2 = false
            this.list2 = []
            this.getList2()
        },
        getList2() {
            if (!this.hasMore2) return
            this.loading2 = true
            this.pageNo2++
            const params = {
                id: this.eventId
            }
            getRankRulesList(params).then((res) => {
                if (res.code) return
                const D = res.result
                let list = []
                let everyday = []
                if (D.rankingListTypeList.some(x => x === 'everyday')) {
                    const everydayInfo = D.rankingListModelDetailVoList.filter(x => x.rankingListType === 'everyday')
                    everyday = everydayInfo[0].rankingListTypeModelDetailVoList?.sort((a, b) => a.whichRank - b.whichRank)
                    this.everyday = everyday
                    if (everyday.length) list = everyday.map(x => { return { whichRank: x.whichRank, everydayRewardRatio: x.rewardRatio, everydayRewardAmt: x.rewardAmt, everydayPrizePoolRewardsType: everydayInfo[0].prizePoolRewardsType } })
                }
                let weekly = []
                if (D.rankingListTypeList.some(x => x === 'weekly')) {
                    const weeklyInfo = D.rankingListModelDetailVoList.filter(x => x.rankingListType === 'weekly')
                    weekly = weeklyInfo[0].rankingListTypeModelDetailVoList?.sort((a, b) => a.whichRank - b.whichRank)
                    this.weekly = weekly
                    if (list.length) {
                        if (weekly.length) {
                            list.forEach((x) => {
                                weekly.forEach((z) => {
                                    if (x.whichRank === z.whichRank) {
                                        x.weeklyRewardRatio = z.rewardRatio
                                        x.weeklyPrizePoolRewardsType = weeklyInfo[0].prizePoolRewardsType
                                        x.weeklyRewardAmt = z.rewardAmt
                                    }
                                })
                            })
                        }
                    } else {
                        if (weekly.length) list = weekly.map(x => { return { whichRank: x.whichRank, weeklyRewardRatio: x.rewardRatio, weeklyRewardAmt: x.rewardAmt, weeklyPrizePoolRewardsType: weeklyInfo[0].prizePoolRewardsType } })
                    }
                }
                let monthly = []
                if (D.rankingListTypeList.some(x => x === 'monthly')) {
                    const monthlyInfo = D.rankingListModelDetailVoList.filter(x => x.rankingListType === 'monthly')
                    monthly = monthlyInfo[0].rankingListTypeModelDetailVoList?.sort((a, b) => a.whichRank - b.whichRank)
                    this.monthly = monthly
                    if (list.length) {
                        if (monthly.length) {
                            list.forEach((x) => {
                                monthly.forEach((z) => {
                                    if (x.whichRank === z.whichRank) {
                                        x.monthlyRewardRatio = z.rewardRatio
                                        x.monthlyPrizePoolRewardsType = monthlyInfo[0].prizePoolRewardsType
                                        x.monthlyRewardAmt = z.rewardAmt
                                    }
                                })
                            })
                        }
                    } else {
                        if (monthly.length) list = monthly.map(x => { return { whichRank: x.whichRank, monthlyRewardRatio: x.rewardRatio, monthlyRewardAmt: x.rewardAmt, monthlyPrizePoolRewardsType: monthlyInfo[0].prizePoolRewardsType } })
                    }
                }
                this.list2 = list
                this.hasMore2 = false
            }).finally(() => {
                setTimeout(() => {
                    this.triggered2 = false
                    this.loading2 = false
                }, 200)
            })
        }
    }
}
</script>

<style scoped lang="scss">
.Rules {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .title {
        color: #fff;
        font-size: .3rem;
        padding: .2rem 0;
        padding-left: .28rem;
        font-weight: bold;
        text-align: left;
    }

    .table {
        display: flex;
        flex-direction: column;
        flex: 1;
        border-radius: .1rem;
        margin: 0 .28rem;
        overflow: hidden;
        padding-bottom: .2rem;

        .tHeader {
            color: #000321;
            font-size: .24rem;
            height: 1rem;
            display: flex;
            align-items: center;
            background-color: #FFC412;
            height: .9rem;
            line-height: .32rem;
            position: relative;
            z-index: 1;
            border-radius: .16rem;
            flex-shrink: 0;

            &>div:first-child {
                width: 20%;
            }

            &>div {
                flex: 1;
                text-align: center;
            }
        }

        .tBody {
            flex: 1;
            border-top: 0;
            border-radius: 0 0 .3rem .3rem;
            width: 100%;
            box-sizing: border-box;
            overflow: auto;

            .tr {
                display: flex;
                align-items: center;
                border-bottom: .01rem dashed rgba(255, 255, 255, 0.4);
                height: .8rem;

                &:nth-child(2n+1) {
                    background: #666D7C;
                    border-radius: .12rem;
                }

                &>div:first-child {
                    width: 20%;
                }

                &>div {
                    flex: 1;
                    color: #A9ACAB;
                    font-size: 18upx;
                    text-align: center;
                    line-height: 24upx;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:not(:first-child) {
                        color: #FFC480;
                    }
                }
            }

            .last-tips {
                line-height: .62rem;
                width: 100%;
                text-align: center;
                font-size: .22rem;
                color: #bbb;
            }

            .nodata {
                width: 100%;
                height: 6.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &>img {
                    width: 4.26rem;
                    height: 2.77rem;
                }

                .empty {
                    margin-top: .41rem;
                    text-align: center;
                    color: #A9ACAB;
                    font-size: .24rem;
                }
            }
        }
    }
}
</style>
