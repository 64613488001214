<template>
    <van-popup v-model="show" @click-overlay="close">
        <div class="content">
            <div class="header">
                <div>{{ $t("Withdraw.VerifyIdentity") }}</div>
                <div class="close">
                    <i class="iconfont icon-s-cuowu-guanbi" @click.stop="close"></i>
                </div>
            </div>
            <div class="info_box">
                <div class="withdral_input_item">
                    <div class="input_title">{{ $t('bankset.WithdrawPassword') }}</div>
                    <div class="page_input">
                        <a-input-password v-model="formData.withdrawPassword"
                            :placeholder="$t('bankset.InputWithdrawalPassword')" allowClear></a-input-password>
                    </div>
                </div>
            </div>
            <div class="action_btn" @click="handleWithdraw">
                {{ $t('Withdraw.Withdraw') }}
                {{ CurrencyType }}{{ formData.orderWithdrawAmt }}
            </div>
        </div>
    </van-popup>
</template>

<script>
import md5 from 'md5'
import { Toast } from 'vant'
import { createMemberWithdrawOrder } from '@/api/withdraw'
import { deepClone } from '@/utils'
export default {
    name: 'VerifyIdentityPopup',
    props: {
        form: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            show: false,
            formData: {},
            canCallHandleWithDraw: true,
            showPassword: false
        }
    },
    methods: {
        open() {
            this.show = true
            this.formData = this.form
            this.formData.withdrawPassword = ''
        },
        close() {
            this.formData = {}
            this.show = false
        },
        handleWithdraw() {
            if (this.canCallHandleWithDraw) {
                this.canCallHandleWithDraw = false
                if (!this.formData.withdrawPassword) {
                    Toast(this.$t('bankset.PleaseEnterWithdrawPassword'))
                    this.canCallHandleWithDraw = true
                    return
                }
                if (this.loading) {
                    return
                }
                const form = deepClone(this.formData)
                form.withdrawPassword = md5(form.withdrawPassword)
                createMemberWithdrawOrder(form).then(res => {
                    if (!res.code && !this.canCallHandleWithDraw) {
                        this.formData.code = ''
                        this.formData.withdrawPassword = ''
                        this.$emit('WithDrawSucc', res)
                        this.close()
                    }
                    if (res.code) {
                        if (res.code === 6301) {
                            this.$emit('MaintenanceTime', res)
                        } else {
                            Toast(res.message)
                        }
                    }
                }).finally(() => {
                    this.canCallHandleWithDraw = true
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/common/styles/viriables.scss';

.content {
    padding: .3rem 0;
    box-sizing: border-box;
    width: 6.94rem;
    max-width: 93%;
    background: $bg-popup-color;
    border-radius: .5rem;
    padding-top: 0;
    overflow: hidden;

    .header {
        display: flex;
        height: 1rem;
        padding-left: .46rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: .1rem;
        background: #0F212E;

        &>div:first-child {
            font-size: .32rem;
            font-weight: bold;
            color: #fff;
        }

        .close {
            color: #516382;
            font-size: .36rem;
            font-weight: bold;
            height: 1rem;
            width: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .info_box {
        border-radius: .32rem;

        .withdral_input_item {
            padding: 0 .28rem;
            padding-top: .08rem;

            .input_title {
                padding: .2rem 0;
                font-size: .28rem;
                color: #FFFFFF;
                line-height: .36rem;
                text-align: left;
            }

            .page_input {
                ::v-deep input::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }

                ::v-deep input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }

                ::v-deep input[type='number'] {
                    -moz-appearance: textfield;
                }

                ::v-deep .ant-input-suffix {
                    .ant-input-clear-icon {
                        color: #666;
                        font-size: .32rem;
                        margin-right: .1rem;
                    }

                    .icon-xiangyou {
                        margin-right: .1rem;
                        font-size: .3rem;
                        color: #516382;
                    }
                }

                ::v-deep .ant-input-password {
                    .ant-input {
                        padding-right: 1rem;
                    }

                    .ant-input-suffix {
                        .ant-input-password-icon {
                            margin-right: .1rem;
                            font-size: .3rem;
                            color: #516382;
                        }
                    }
                }

                ::v-deep .ant-input {
                    background-color: #000 !important;
                    border-radius: 0.14rem;
                    font-size: 0.22rem;
                    height: .8rem;
                    padding: 0px 0.24rem !important;
                    color: #e3e3e3;
                    border: .02rem solid #383e4b !important;
                    padding-left: .28rem !important;

                    &:focus {
                        background-color: transparent;
                        border-color: #286aff !important;
                    }

                    &::-webkit-input-placeholder {
                        /* WebKit browsers */
                        font-size: .22rem;
                        color: #516382;
                        opacity: .7;
                        font-weight: 100;
                    }

                    &:-moz-placeholder {
                        /* Mozilla Firefox 4 to 18 */
                        font-size: .22rem;
                        color: #516382;
                    }

                    &::-moz-placeholder {
                        /* Mozilla Firefox 19+ */
                        font-size: .22rem;
                        color: #516382;
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10+ */
                        font-size: .22rem;
                        color: #516382;
                    }
                }
            }
        }
    }

    .action_btn {
        width: 88%;
        height: .7rem;
        border-radius: .15rem;
        margin: .3rem auto;
        margin-top: .58rem;
        font-family: eryawenrunti;
        font-weight: 600;
        font-size: .28rem;
        color: #fff;
        text-align: center;
        line-height: .7rem;
        cursor: pointer;
        background-color: #ff7300;

        &.disabled {
            background: #9D9E9F;
            color: rgba(255, 255, 255, 0.45);
            cursor: not-allowed;
            box-shadow: 0 .06rem 0 0 #2D3241, 0 .02rem .03rem 0 #333, 0 .02rem .03rem 0 #999;
        }
    }
}
</style>
