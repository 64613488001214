import request from '@/common/http/request'

// 活动列表
export function getEventsList(data) {
    return request({
        url: '/eventManage/eventAllList',
        method: 'post',
        data
    })
}
// 自定义活动获取活动信息
export function getCustomEventInfo(data) {
    return request({
        url: '/memberCustom/info',
        method: 'post',
        data
    })
}
// 注册奖励信息
export function getRegisterRewardsVoInfo(data) {
    return request({
        url: '/registerRewards/getRegisterRewardsVoInfo',
        method: 'post',
        data
    })
}
