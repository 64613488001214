import request from '@/common/http/request'

// 获取排行榜活动规则
export function getRankRulesList(data) {
    const timestamp = new Date().getTime()
    return request({
        url: '/rankingList/getMemberRankingListRulesInfo?timestamp=' + timestamp,
        method: 'post',
        data
    })
}
// 获取当前排行榜列表
export function getNowRankingList(data) {
    return request({
        url: '/rankingList/getNowDayRankingInfoMessage',
        method: 'post',
        data
    })
}
// 获取我的历史的排行榜列表
export function getHistoryRankingList(data) {
    const timestamp = new Date().getTime()
    return request({
        url: '/rankingList/getMyRankingListHistoryInfoVo?timestamp=' + timestamp,
        method: 'post',
        data
    })
}
// 获取历史的排行榜列表
export function getHistoryRankList(data) {
    return request({
        url: '/rankingList/getMemberRankingListHistoryInfo',
        method: 'post',
        data
    })
}
