import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import '@vant/touch-emulator'
import 'vant/lib/index.css'
import '@/common/iconfont/iconfont.css'
import VueI18n from 'vue-i18n'
import en from '@/common/locales/en.js'
import ptBR from '@/common/locales/ptBR.js'
import esMX from '@/common/locales/esMX.js'
import ruRU from '@/common/locales/ruRU.js'
import mixin from '@/mixin'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import websocket from '@/utils/webSocket'
import loginPopup from '@/components/loginPopup'
import depositPopup from '@/components/depositPopup'
import withdrawPopup from '@/components/withdrawPopup'
import bankSet from '@/components/withdrawPopup/bankSet.vue'
import loadingPopup from '@/components/loadingPopup'
Vue.component('loginPopup', loginPopup)
Vue.component('depositPopup', depositPopup)
Vue.component('withdrawPopup', withdrawPopup)
Vue.component('bankSet', bankSet)
Vue.component('loadingPopup', loadingPopup)
Vue.use(Antd)
Vue.use(Vant)
Vue.use(VueI18n)
Vue.prototype.$websocket = websocket
const i18n = new VueI18n({
    locale: 'en_US',
    messages: {
        en_US: en,
        pt_BR: ptBR,
        es_MX: esMX,
        ru_RU: ruRU
    }
})

Vue.config.productionTip = false
Vue.mixin(mixin)
new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
