import request from '@/common/http/request'
// 获取游戏分类
export function getGameClassify(data) {
    return request({
        url: '/gameTitle/listEnable',
        method: 'post',
        data
    })
}
// 获取游戏列表
export function getGameList(data) {
    return request({
        url: '/gameInfo/pageByTitle',
        method: 'post',
        data
    })
}
// 获取游戏All根据分类(新)
export function getAllGameListByClassify(data) {
    return request({
        url: '/gameInfo/queryGameListByAllTitle',
        method: 'post',
        data
    })
}
// 游戏列表收藏(新)
export function getGameCollect(data) {
    return request({
        url: '/memberGameFav/pageGameInfoFavorites',
        method: 'post',
        data
    })
}
// 轮播
export function homeInfo(data) {
    return request({
        url: '/home/getAll',
        method: 'post',
        data
    })
}
// 首页获取活动
export function getEventsHomeShow(data) {
    const timestamp = new Date().getTime()
    return request({
        url: '/eventManage/homeShow?timestamp=' + timestamp,
        method: 'post',
        data
    })
}
// 友情链接
export function getFriendLogoList(data) {
    return request({
        url: '/home/friendLink',
        method: 'post',
        data
    })
}
