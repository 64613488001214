import { Toast } from 'vant'
import handleCopy from '@/utils/copy.js'
import { inviteCodeKey } from '@/common/http'
import { verifyToken } from '@/api/user'
import store from '@/store'
export default {
    data() {
        return {
            langObj: {
                en_US: {
                    type: 'en_US',
                    name: 'English',
                    img: require('@/assets/lang/usa.png')
                },
                pt_BR: {
                    type: 'pt_BR',
                    name: 'Português',
                    img: require('@/assets/lang/brazil.png')
                },
                es_MX: {
                    type: 'es_MX',
                    name: 'Español',
                    img: require('@/assets/lang/Peru.jpg')
                },
                ur_PK: {
                    type: 'ur_PK',
                    name: 'پاکستان',
                    img: require('@/assets/lang/pakistan.png')
                },
                ru_RU: {
                    type: 'ru_RU',
                    name: 'Russia',
                    img: require('@/assets/lang/Russia.webp')
                }
            },
            CurrencyTypeObj: {
                Brazil: 'R$',
                Pakistan: 'Rs',
                Nigeria: '₦',
                India: '₹',
                Peru: 'S/',
                Russia: '₽‌'
            },
            timeZoneObj: {
                Brazil: -3,
                Pakistan: 5,
                Nigeria: 1,
                India: 5.5,
                Peru: -5,
                Russia: 3
            }
        }
    },
    computed: {
        CurrencyType() {
            let Currency = ''
            const nation = store.getters.sysConfig?.nation
            if (nation) Currency = this.CurrencyTypeObj[nation]
            return Currency
        },
        BelongNation() {
            const nation = store.getters.sysConfig?.nation
            return nation
        },
        DisabledBelongCountry() {
            const isDisabled = store.getters.sysConfig?.disabledBelongCountry
            return isDisabled
        },
        isDownAppCanPlayGame() {
            const isDisabled = store.getters.sysConfig?.isDownAppCanPlayGame
            return isDisabled
        }
    },
    created() {
        // const that = this
        const opt = this.$route?.query
        if (opt && opt.token) {
            store.commit('user/SET_TOKEN', opt.token)
        }
        if (opt && opt.ta) {
            store.dispatch('system/setPixel', opt.ta)
        }
        if (opt && opt.taid) {
            store.dispatch('system/setTaId', opt.taid)
            if (opt.ta && opt.ta === 'FaceBookPixel') {
                fbq('init', opt.taid) // eslint-disable-line
                fbq('set', 'mobileBridge', opt.taid, '') // eslint-disable-line
                fbq('track', 'PageView') // eslint-disable-line
                const noscript = document.createElement('noscript')
                const img = document.createElement('img')
                img.setAttribute('height', '1')
                img.setAttribute('width', '1')
                img.setAttribute('style', 'display:none')
                img.setAttribute('src', `https://www.facebook.com/tr?id=${opt.taid}&ev=PageView&noscript=1`)
                noscript.appendChild(img)
                document.head.appendChild(noscript)
                console.log('调用了fbq初始化init api,facebookId为', opt.taid)
            }
            if (opt && opt.ta && opt.ta === 'Kwai') {
                kwaiq.load(opt.taid) // eslint-disable-line
                kwaiq.page() // eslint-disable-line
            }
        }
        if (opt && opt.click_id) {
            store.dispatch('system/setClickId', opt.click_id)
        }
        if (opt && opt[inviteCodeKey]) {
            const invitCodeNum = store.getters.invitCodeNum
            if (invitCodeNum !== 2) store.dispatch('user/setInvitCodeNum', 1)
            store.dispatch('user/setInvitCode', opt[inviteCodeKey])
        } else {
            const url = decodeURIComponent(window.location.href)
            const sceneObj = {}
            url.split('#')[0].replace('?', '&').split('&').filter(item => {
                const key = item.split('=')
                sceneObj[key[0]] = key[1]
            })
            // console.log(scene)
            if (sceneObj[inviteCodeKey]) {
                const invitCodeNum = store.getters.invitCodeNum
                if (invitCodeNum !== 2) store.dispatch('user/setInvitCodeNum', 1)
                store.dispatch('user/setInvitCode', sceneObj[inviteCodeKey])
            } else if (url.indexOf(inviteCodeKey + '=') !== -1) {
                let code = url.split(inviteCodeKey + '=')[1]
                code = code.slice(0, 6)
                const invitCodeNum = store.getters.invitCodeNum
                if (invitCodeNum !== 2) store.dispatch('user/setInvitCodeNum', 1)
                store.dispatch('user/setInvitCode', code)
            }
            if (sceneObj.ta) {
                store.dispatch('system/setPixel', sceneObj.ta)
            }
            if (sceneObj.taid) {
                store.dispatch('system/setTaId', sceneObj.taid)
                if (sceneObj.ta && sceneObj.ta === 'FaceBookPixel') {
                    fbq('init', sceneObj.taid) // eslint-disable-line
                    fbq('set', 'mobileBridge', sceneObj.taid, '') // eslint-disable-line
                    fbq('track', 'PageView') // eslint-disable-line
                    const noscript = document.createElement('noscript')
                    const img = document.createElement('img')
                    img.setAttribute('height', '1')
                    img.setAttribute('width', '1')
                    img.setAttribute('style', 'display:none')
                    img.setAttribute('src', `https://www.facebook.com/tr?id=${sceneObj.taid}&ev=PageView&noscript=1`)
                    noscript.appendChild(img)
                    document.head.appendChild(noscript)
                    console.log('调用了fbq初始化init api,facebookId为', sceneObj.taid)
                }
                if (sceneObj.ta && sceneObj.ta === 'Kwai') {
                    kwaiq.load(sceneObj.taid) // eslint-disable-line
                    kwaiq.page() // eslint-disable-line
                }
            }
            if (sceneObj.click_id) {
                store.dispatch('system/setClickId', sceneObj.click_id)
            }
        }
        if (store?.getters.invitCode && (!store?.getters.token) && store?.getters.invitCodeNum === 1) {
            store.dispatch('user/setInvitCodeNum', 2)
            store.dispatch('user/changeLoginType', 1)
            store.dispatch('user/setIsShowLoginPopup', true)
        }
    },
    methods: {
        /**
        * url
        * type 跳转类型 href get post
        * replace 是否去掉历史记录
        */
        onNavTo({ url, type, params, replace }) {
            const opt = params || {}
            if (type === 'href') {
                window.open(url)
            } else if (type === 'get') {
                // 会把要传递的参数显示在导航栏中
                // 新页面取参(刷新页面参数不会丢失)：this.$route.query.id
                this.$router.push({ path: url, query: opt })
            } else if (type === 'post') {
                // 默认不会把传递的参数显示在导航栏中
                // 新页面取参(刷新页面参数会丢失)：this.$route.params.id
                // 不想丢失可以在路由地址上添加预置位：
                // router配置
                // const routes = [{
                //     path: '/list/:id',
                //     name: 'list',
                //     component: List
                // }]
                this.$router.push({ name: url, params: opt })
            } else {
                if (replace === 1) {
                    this.$router.replace({ path: url })
                } else {
                    this.$router.push({ path: url, query: opt })
                }
            }
        },
        goBack() {
            const historyLength = this.$router.history.length
            if (historyLength) {
                this.$router.back()
            } else {
                if (this.$route.path.indexOf('/Home') !== -1) {
                    window.location.reload()
                } else {
                    this.onNavTo({ url: '/Home', replace: 1 })
                }
            }
        },
        onNavToByIsLogin({ url, type, params, replace }) {
            return new Promise((resolve, reject) => {
                verifyToken().then((res) => {
                    if (res.code === 0) {
                        if (url) this.onNavTo({ url, type, params, replace })
                        resolve(true)
                    }
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        // 复制
        onCopy(v) {
            if (!v) return
            handleCopy({
                content: v,
                success: (res) => {
                    Toast(res)
                },
                error: (e) => {
                    Toast(e)
                }
            }, this)
        },
        getLocalTime() {
            const nation = store.getters.sysConfig?.nation
            let i = 8
            if (nation) i = this.timeZoneObj[nation]
            if (typeof i !== 'number') return
            const d = new Date()
            const len = d.getTime()
            const offset = d.getTimezoneOffset() * 60000
            const utcTime = len + offset
            return utcTime + 3600000 * i
        }
    }
}
