<template>
    <div class="Opend">
        <NotificationContent ref="NotificationContent" @close="close"></NotificationContent>
    </div>
</template>

<script>
import NotificationContent from '@/components/notification/content.vue'
import { mapState } from 'vuex'
export default {
    name: 'Notification',
    components: { NotificationContent },
    data() {
        return {}
    },
    computed: {
        ...mapState({
            isShowNotification: state => state.app.isShowNotification
        })
    },
    watch: {
        isShowNotification(newVal, oldVal) {
            this.$nextTick(() => {
                if (newVal) this.$refs.NotificationContent.open()
            })
        }
    },
    methods: {
        close() {
            this.$store.dispatch('app/toggleNotification')
        }
    }
}
</script>

<style lang="scss" scoped>
.Opend {
    display: block;
    box-sizing: border-box;
    // padding: 18px 10px;
    padding-right: 0;
    overflow: auto;
    width: 280px;
    background-color: #17191f;
    transition-duration: 0.3s;
    position: relative;
    z-index: 2;
    box-shadow: -8px 0 8px #00000040;
}
</style>
