<template>
    <van-popup v-model="show" round @click-overlay="close">
        <div class="tips_pop">
            <div class="tips_top">
                <div class="tips_title">{{ $t("lang.tips") }}</div>
                <div class="icon-close" @click="close">
                    <i class="iconfont icon-s-cuowu-guanbi"></i>
                </div>
            </div>
            <div class="tips_box">
                <slot>
                    {{ tips }}
                </slot>
            </div>
            <div class="tips_footer" @click="confirm">
                {{ $t("lang.confirm") }}
            </div>
        </div>
    </van-popup>
</template>

<script>
export default {
    props: {
        tips: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        open() {
            this.show = true
        },
        close() {
            this.show = false
            this.$emit('close')
        },
        confirm() {
            this.show = false
            this.$emit('confirm')
        }
    }
}
</script>

<style scoped lang="scss">
.tips_pop {
    padding: .31rem;
    box-sizing: border-box;
    width: 6.9rem;
    background: #1d2027;
    border-radius: .2rem;
    margin: 0 auto;
    padding-bottom: .06rem;

    .tips_top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tips_title {
            font-weight: 400;
            font-size: .28rem;
            color: #FFFFFF;
            font-size: .24rem;
        }

        .icon-close {
            width: 28px;
            height: 28px;
            background: rgba(0, 0, 0, .4);
            border-radius: 50px;
            font-weight: 700;
            box-sizing: border-box;
            position: absolute;
            top: 10px;
            right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            cursor: pointer;

            &>i {
                color: #fff;
                font-size: .22rem;
                vertical-align: middle;
            }
        }
    }

    .tips_box {
        margin: .4rem auto;
        color: rgba(255, 255, 255, 0.80);
        font-size: .24rem;
    }

    .tips_footer {
        width: 100%;
        box-sizing: border-box;
        height: .9rem;
        border-top: .01rem solid #414148;
        line-height: .9rem;
        text-align: center;
        font-size: .3rem;
        font-weight: bold;
        color: #286aff;
    }
}
</style>
