import request from '@/common/http/request'

// 获取用户的定时红包信息
export function getTimedRedPacketInfo(data) {
    return request({
        url: '/memberRedPacket/info',
        method: 'post',
        data
    })
}
// 用户获取定时红包
export function getTimedRedPacket(data) {
    return request({
        url: '/memberEventTimedRedPacket/memberCollectEventTimedRedPacket',
        method: 'post',
        data
    })
}
