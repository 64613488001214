export default function handleCopy({ content, success, error }, that) {
    if (!content) return error(that.$t('lang.CopyEmptyTips'))
    content = typeof content === 'string' ? content : content.toString() // 复制内容，必须字符串，数字需要转换为字符串

    navigator.clipboard?.writeText(content).then(
        (res) => {
            success(that.$t('lang.copySuccess'))
        },
        (err) => {
            console.log(err)
            success(that.$t('lang.copyError'))
        }
    )

    /**
	 * H5端的复制逻辑
	 */
    if (!document.queryCommandSupported('copy')) { // 为了兼容有些浏览器 queryCommandSupported 的判断
        // 不支持
        error(that.$t('lang.copyError'))
    }
    const textarea = document.createElement('textarea')
    textarea.value = content
    textarea.readOnly = 'readOnly'
    document.body.appendChild(textarea)
    textarea.select() // 选择对象
    textarea.setSelectionRange(0, content.length) // 核心
    const result = document.execCommand('copy') // 执行浏览器复制命令
    if (result) {
        success(that.$t('lang.copySuccess'))
    } else {
        error(that.$t('lang.copyError'))
    }
    textarea.remove()
}
