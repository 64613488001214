import request from '@/common/http/request'

// 系统基本信息
export function getSysBaseConfig(data) {
    const timestamp = new Date().getTime()
    return request({
        url: '/home/sysBaseConfig?timestamp=' + timestamp,
        method: 'post',
        data
    })
}
// Facebook推广信息
export function getFacebookConfig(data) {
    return request({
        url: '/setFacebookConfig/get',
        method: 'post',
        data
    })
}
// 获取国家基本信息
export function getNationInfo(data) {
    const timestamp = new Date().getTime()
    return request({
        url: '/enum/Nation?timestamp=' + timestamp,
        method: 'post',
        data
    })
}
