export default {
    lang: {
        LoadMore: 'Cargar más',
        AllLoaded: 'Todo cargado',
        ChangeLanguage: 'Cambiar idioma',
        ChangePassword: 'Cambiar contraseña',
        copySuccess: '¡Éxito al copiar!',
        copyError: 'Error al copiar',
        CopyEmptyTips: 'El contenido copiado no puede estar vacío',
        empty: 'Vacío aquí',
        tips: 'Consejos',
        cancel: 'Cancelación',
        confirm: 'Confirmar',
        more: 'Mais',
        isCloseGame: '¿¿ se cierra el juego?',
        back: 'Atrás',
        Claim: 'Afirmar',
        Loading: 'Cargando',
        Play: 'Jugar',
        Download: 'Descargar'
    },
    tabbar: {
        Lobby: 'Principal',
        Earn: 'Compartir',
        Game: 'Juego',
        Event: 'Incidencias',
        Account: 'Cuenta',
        Recharge: 'Recargar',
        serve: 'Servicios',
        Withdraw: 'Retirada',
        Affiliate: 'Filial'
    },
    home: {
        DownloadNow: 'Descargar ahora',
        DownloadTips: 'Descarga la aplicación y obtén ',
        Search: 'Buscar',
        Popular: 'Popular',
        Recent: 'Reciente',
        Favorite: 'Favorito',
        Providers: 'Proveedoras',
        CollectionSucc: 'Colección exitosa',
        CollectionCanceled: 'Colección cancelada',
        MORE: 'MÁS',
        Top: 'Arriba'
    },
    login: {
        Login: 'Iniciar sesión',
        PhoneLogin: 'Inicio de sesión telefónico',
        MemberAccount: 'Cuenta de membresía',
        Password: 'Palabras clave',
        PleaseEnterAccount: 'Please enter your account number',
        PleaseEnterPassword: 'Por favor, introduzca la contraseña',
        RememberMe: 'Recuérdame',
        ForgetPassword: '¿Olvidó la contraseña?'
    },
    signin: {
        signInTitle: 'Bonificación diaria por inicio de sesión',
        signInDays: 'Iniciado sesión continuamente durante ',
        days: ' días',
        day: 'DÍA',
        claimed: 'Reclamado ',
        condition1: 'Depósito requerido',
        condition2: 'Apuestas requeridas',
        eventDetails: 'Detalles del evento',
        checkin: 'Registro',
        CheckIn: 'REGISTRARSE',
        Today: 'Hoy',
        Uncompleted: 'INCOMPLETO',
        Completed: 'COMPLETO',
        Conditions: 'Condiciones',
        Rules: 'Reglas'
    },
    share: {
        panel: 'Panel',
        FAQ: 'FAQ',
        TUTORIAL: 'TUTORIAL',
        TotalRewards: 'Recompensas Totales',
        TotalFriends: 'Total de Amigos',
        inviteFriend: 'INVITA AMIGOS PARA GANAR',
        Commission: 'Comisión',
        InviteVia: 'Invitar a través de:',
        copy: 'Copiar',
        link: 'Enlace',
        code: 'Código',
        or: 'O',
        Rewards: 'Recompensas',
        title1: 'COMISIÓN',
        tip1: 'Gana hasta',
        tip2: 'comisión cuando tu amigo apueste.',
        title2: 'CASHBACK DE DEPÓSITO',
        tip3: 'Gana',
        tip4: 'cuando tu amigo deposite.',
        Details: 'Detalles',
        UncollectedCommissions: 'Comisiones no cobradas',
        ExclusiveCommission: 'Comisión Exclusiva',
        DepositCashback: 'Cashback de depósito',
        Claim: 'Reclamar',
        TodayTeamData: 'Datos del equipo de hoy',
        TotalTeamData: 'Datos totales del equipo',
        SignUp: 'Registrarse',
        FirstDeposit: 'Primer Depósito',
        ValidBet: 'Apuesta Válida',
        LiveRewards: 'Recompensas en Vivo',
        SendTotalCommission: 'Enviar comisión total',
        rules: 'Normas',
        ruleInfo: '¡Puedes ganar dinero recomendando amigos y ganar hasta comisiones!',
        Recommended: 'Recomendada',
        Month: 'Mes',
        UnlimitedSubordinates: 'Desarrollo ilimitado de subordinados',
        planTitle: 'APRENDE MÁS SOBRE NUESTRO PROGRAMA DE AFILIADOS',
        planInfo: 'Si tienes una gran audiencia y seguidores. ¡Tenemos condiciones especiales para que personalices tu programa de referencia!',
        all: 'Todos',
        Direct: 'Directo',
        Indirect: 'Indirecto',
        TodayTeamIntro1: 'Registrarse: Número de usuarios que se registraron hoy',
        TodayTeamIntro2: 'Primer Depósito: Número de usuarios que hicieron su primer depósito hoy',
        TodayTeamIntro3: 'Apuestas Válidas: Apuestas válidas para el equipo de hoy',
        TotalTeamIntro1: 'Registrarse: Total de inscripciones del equipo',
        TotalTeamIntro2: 'Primer Depósito: Total de depositantes por primera vez',
        TotalTeamIntro3: 'Apuestas Válidas: El total de apuestas efectivas del equipo'
    }
}
